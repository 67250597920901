import React from 'react';
import { withRouter } from 'react-router-dom';

function BackButton({ history }) {
    const onClick = () => {
        history.goBack();
    }
    return (
        <button
            onClick={onClick}
            className="btn btn-secondary"
            style={{ marginRight: '15px' }}>
            <i className="la la-arrow-left"></i> Назад
        </button>
    );
}

export default withRouter(BackButton);