import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addCategory, deleteCategory, setActiveModalAction, updateCategory } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import { getImageUrl } from '../../utils/common';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Switch from '../../components/utils/Switch';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';

const SelectCity = ({ selected, onChange }) => {
  const dispatch = useDispatch();
  const loadingCities = useSelector(fromStore.loaderCitiesSelector);
  const cities = useSelector(fromStore.citiesListSelector);

  const onChangeSelect = (values) => {
    onChange(values ? values.map((item) => String(item.id)).join(',') : '');
  };

  const categoryCitiesIds = selected ? selected.split(',') : [];
  const selectedCities = cities.filter((item) => categoryCitiesIds.includes(String(item.id)));

  useEffect(() => {
    if (isEmpty(cities)) {
      dispatch(fetchCities(true, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Недоступен в городах</label>
        <div className="col-lg-9 col-xl-9">
          <Select
              isLoading={loadingCities}
              isDisabled={loadingCities}
              value={selectedCities}
              options={cities}
              getOptionLabel={(item) => `${item.name}`}
              getOptionValue={(item) => item.id}
              onChange={onChangeSelect}
              isMulti
              isClearable={false}
              placeholder="Выберите город(а)..."
              noOptionsMessage={() => 'Нет городов'}
          />
        </div>
    </div>
  );
}

export function CategoryModal() {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).category;
  const loading = useSelector(fromStore.loaderModalCategorySelector);
  const category = useSelector(fromStore.categoryById(id));
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [data, setData] = useState({
    title: category ? category.title : '',
    key: category ? category.key : '',
    inner: category ? category.inner : null,
    oldId: category ? category.oldId : null,
    disabledCities: category ? category.disabledCities || '' : '',
    disabledPoint: category ? category.disabledPoint || '' : '',
    priority: category && category.priority ? category.priority : 0,
  });

  const isEditMode = id !== 'new';

  const onChangeInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.value;
    setData(updated);
  };

  const onChangeSwitch = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  };

  const onSetFile = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.files[0];
    setData(updated);
  };

  const isValid = () => {
    const { title, imagePrev, image, key } = data;
    if (isEditMode) {
      if (title || imagePrev || image || key) return true;
    }
    if (title && imagePrev && image && key) return true;

    return false;
  }

  const onClose = () => dispatch(setActiveModalAction({ field: 'category', value: null }));
  useOnClickOutside(ref, () => !confirmDelete && onClose());

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isEditMode) {
      const result = await dispatch(addCategory(data));
      if (result) {
        onClose();
      }
    } else {
      const rq = { ...data };
      if (rq.image) {
        rq.imageData = category.image;
      }
      if (rq.imagePrev) {
        rq.imagePrevData = category.imagePrev;
      }
      const result = await dispatch(updateCategory(id, rq));
      if (result) {
        onClose();
      }
    }
  };

  const onDelete = (id) => () => {
    setConfirmDelete(true);
    swal({
        title: `Удаление категории`,
        text: `Вы действительно хотите удалить категорию?`,
        icon: "warning",
        dangerMode: true,
        buttons: ["Отмена", "Удалить"],
    }).then((willDelete) => {
        if (willDelete) {
            dispatch(deleteCategory(id));
        }
        setConfirmDelete(false);
    });
  }

  const onChangeSelect = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  }

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование категории' : 'Добавление категории'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="c-title" className="form-control-label">Заголовок *</label>
                  <input disabled={loading} type="text" value={data.title} className="form-control" id="c-title" onChange={onChangeInput('title')} />
                </div>
                <div className="form-group">
                  <label htmlFor="c-priority" className="form-control-label">Приоритет</label>
                  <input disabled={loading} type="number" value={data.priority} className="form-control" id="c-priority" onChange={onChangeInput('priority')} />
                </div>
                <div className="form-group">
                  <label htmlFor="c-old-id" className="form-control-label">Старый ID</label>
                  <input disabled={loading} type="number" value={data.oldId} className="form-control" id="c-old-id" onChange={onChangeInput('oldId')} />
                </div>
                <div className="form-group">
                  <label htmlFor="c-key" className="form-control-label">Ключ (для url) *</label>
                  <input disabled={loading} type="text" value={data.key} className="form-control" id="c-key" onChange={onChangeInput('key')} />
                </div>
                <div className="form-group">
                  <label htmlFor="c-prev" className="form-control-label">Превью *</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {category && (
                      <img style={{ marginRight: '20px' }} width="50px" src={ getImageUrl(category.imagePrev) } alt="" />
                    )}
                    <input disabled={loading} onChange={onSetFile('imagePrev')} type="file" style={{ padding: '6px' }} className="form-control" id="c-prev" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="c-image" className="form-control-label">Изображение *</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {category && (
                      <img style={{ marginRight: '20px' }} width="100px" src={ getImageUrl(category.image) } alt="" />
                    )}
                    <input disabled={loading} onChange={onSetFile('image')} type="file" style={{ padding: '6px' }} className="form-control" id="c-image" />
                  </div>
                </div>
              
                <SelectCity selected={data.disabledCities} onChange={onChangeSelect('disabledCities')} />
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Внутренний раздел</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={onChangeSwitch('inner')} checked={!!data.inner}/>
                    </div>
                </div>
                <ErrorContainer field="modalCategory" hasCloseButton={true}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              {isEditMode && (
                <button
                  disabled={loading}
                  type="button"
                  onClick={onDelete(id)}
                  className={cn({ 'btn btn-danger kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loading })}>
                  Удалить</button>
              )}
              <button
                type="submit"
                disabled={!isValid() || loading}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loading })}>
                  {isEditMode ? 'Сохранить' : 'Добавить категорию'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
