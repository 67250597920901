import { setFiltersDocumentation } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = {
  title: null,
  createdStartDate: null,
  createdEndDate: null
};

export default createReducer(
  [forAction(setFiltersDocumentation, identityPayload)],
  initialState,
);
