import { setActiveModalAction } from '../action';
import { createReducer, forAction } from '../utils';

const initialState = {
  category: null,
  good: null,
  stopList: false,
  regionPrice: null,
  modification: null,
  slider: null,
  yookassa: null,
};

export default createReducer(
  [forAction(setActiveModalAction, (state, { payload: { field, value } }) => ({
    ...state,
    [field]: value,
  }))],
  initialState,
);
