import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import Select from 'react-select';
import { timeDelivery, optionsMinDeliveryTime, optionsMinSamTime, roleId } from '../../constants/app'
import _ from 'lodash';
import SelectStopList from './SelectStopList';

const activateSelect = {
    'active': 'Активирован',
    'disabledAll': 'Полностью остановлен',
    'disabledDelivery': 'Доставка остановлена',
    'disabledOwn': 'Самовывоз остановлен',
};

class TableFilials extends Component {
    setFilial = (field, id) => (e) => {
        const filial = this.props.items.filter((filial) => {
            if(filial.id === id) {
                return true
            } else {
                return false
            }
        }).shift()
        
        if (field === 'minDeliveryTime' || field === 'minSamTime') {
            filial[field] = e.value;
        }

        if (field === 'stopList' || field === 'stop' || field === 'directOrder') {
            filial[field] = e;
        } else if (field !== 'minDeliveryTime' && field !== 'minSamTime') {
            filial[field] = e.target.value;
        }
            
        if(_.includes(this.props.editFilials, filial)){
            this.props.changeFilialsEdit(filial)
        } else {
            this.props.addFilialsEdit(filial)
        }
    }

    isValidFilial = (id) => {
        const filial = this.props.editFilials.filter((filial) => {
            if(filial.id === id) {
                return true
            } else {
                return false
            }
        }).shift()

        if(filial) {
            return false;
        } else {
            return true;
        }
    }

    saveFilial = (e, id) => {
        const dat = this.props.editFilials.filter(editFilial => {
            if (editFilial.id === id) {
                return true
            } else {
                return false
            }
        }).shift();

        delete dat.users;

        this.props.updateFilial(id, dat).then(result => {
            if (result) {
                this.props.fetchFilials();
            }
        });

        this.props.removeFilialsEdit(dat)
    }

    resetTime = (e, id) => {
        this.props.fetchFilial(id).then(result => {
            this.props.setFilialEdit({...result, minDeliveryTime: timeDelivery.minDeliveryTime, minSamTime: timeDelivery.minSamTime});
            const dat = { ...this.props.filial.edit };
            delete dat.users;
            this.props.updateFilial(id, dat).then(result => {
                    if (result) {
                        this.props.fetchFilials();
                    }
                });
        });
    }

    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Город</span>
                    </th>

                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Улица</span>
                    </th>

                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Мин. время доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '120px' }}>Мин. время самовывоза</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Состояние</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '300px' }}>Стоп лист товаров</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}></span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/handbook/filials/${+item.id}`)
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }
    
    renderBody() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            //{ access: roleId,admin, name: item.stop ? 'Включить' : 'Выключить', icon: 'flaticon2-contrast', action: () => this.props.activate(item) },
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/filials/${+item.id}`) },
                            { access: [roleId.admin, roleId.coordinator], name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/filials/edit/${+item.id}`) },
                            { access: [roleId.admin], name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.delete(item) }
                        ].filter(item => !item.access || item.access.includes(this.props.role));;
                        return (
                            <tr key={item.id} className="kt-datatable__row">
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>
                                        {item.city.name}
                                    </span>
                                </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>
                                    { item.street }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <div style={{ width: '100px' }}>
                                    <Select
                                        isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledDelivery' || item.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinDeliveryTime}
                                        onChange={this.setFilial('minDeliveryTime', item.id)}
                                        value={{value: item.minDeliveryTime, label: item.minDeliveryTime}}
                                    />
                                </div>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '120px' }}>
                                    <Select
                                        isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledOwn' || item.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinSamTime}
                                        onChange={this.setFilial('minSamTime', item.id)}
                                        value={{value: item.minSamTime, label: item.minSamTime}}
                                    />
                                </span>
                            </td>
                            <td className="kt-datatable__cell" style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                <span style={{ width: '150px' }}>
                                    <select disabled={item.hiddenClient} onChange={(e) => this.setFilial('activate', item.id)(e) } value={item.activate} className="form-control"
                                    >
                                        {
                                            Object.keys(activateSelect).map((key, index) => (
                                                <option key={key} value={key}>{activateSelect[key]}</option>
                                            ))
                                        }
                                    </select>
                                </span>

                                <div className={`point_status_circle ${item.activate}`}></div>
                            </td>

                            <td className="kt-datatable__cell">
                                    <span style={{ width: '300px' }}>
                                        <SelectStopList 
                                            isLoading={this.props.loadingGoods}
                                            isDisabled={this.props.loadingGoods}
                                            goods={this.props.goods}
                                            stopList={item.stopList}
                                            setFilial={this.setFilial}
                                            id={item.id}
                                        />
                                    </span>
                                </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                                     <button
                                        title="Сбросить время"
                                        type="button"
                                        onClick={(e) => {
                                            if(!item.hiddenClient)
                                                this.resetTime(e, item.id)
                                        }}
                                        disabled={(item.minDeliveryTime === timeDelivery.minDeliveryTime && item.minSamTime === timeDelivery.minSamTime) || item.hiddenClient ? true : false}
                                        className="btn btn-outline-secondary btn-sm btn-icon "
                                        >
                                            <i className="flaticon-reply"></i>
                                    </button>
                                    <button
                                        title="Сохранить изменения"
                                        type="button"
                                        onClick={(e) => {
                                            this.saveFilial(e, item.id)
                                        }}
                                        disabled={this.isValidFilial(item.id) || this.props.loading}
                                        className="btn btn-outline-secondary btn-sm btn-icon "
                                        >
                                            <i className="flaticon2-check-mark"></i>
                                        
                                    </button>
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Филиалов не найдено</div>
            );
        }
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px', overflowX: 'auto' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(TableFilials);