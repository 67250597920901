import React from 'react';

export default function InfoBox({ title, subtitle }) {
    return (
        <div className="kt-portlet kt-iconbox kt-iconbox--success kt-iconbox--animate-slow">
            <div className="kt-portlet__body">
                <div className="kt-iconbox__body">
                    <div className="kt-iconbox__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                <path
                                    d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                    id="Combined-Shape"
                                    fill="#000000"
                                    opacity="0.3"
                                ></path>
                                <path
                                    d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                                    id="check-path"
                                    fill="#000000"
                                ></path>
                                <path
                                    d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                    id="Combined-Shape"
                                    fill="#000000"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <div className="kt-iconbox__desc">
                        <h3 className="kt-iconbox__title">
                            <div className="kt-link">{title}</div>
                        </h3>
                        <div className="kt-iconbox__content">
                            {subtitle}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}