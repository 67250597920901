import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../action/user';
import { goodPages } from '../constants/page';
import { Route, Redirect } from 'react-router-dom';
import Content from '../components/layout/Content';

class GoodPage extends Component {
    renderPage = (page) => (props) => {
        if (page.access && !page.access.includes(this.props.auth.role.id)) {
            return <Redirect to="/"/>
        }
        if (props.match.params.id && !/\d+/.test(props.match.params.id)) {
            return null;
        }
        const Component = page.component;

        return (
            <Content subheader={true} title={page.title} fit={page.fit}>
                <Component {...props}/>
            </Content>
        );
    }

    render() {
        return goodPages.map(page => (
            <Route
                key={page.name}
                exact
                path={page.path}
                render={this.renderPage(page)}
            />
        ));
    }
}

const mapStateToProps = state => ({
    users: state.users.list,
    loading: state.loaders.content,
    auth: state.user.info
});

const mapDispatchToProps = {
    fetchUsers: () => fetchUsers()
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodPage);