import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import Loader from '../../../components/utils/Loader';
import { isEmpty } from 'lodash';
import { ItemRow } from './ItemRow';
import { TableHeader } from './TableHeader';
import { useDispatch } from 'react-redux';
import { fetchPhoneCodes } from '../../../action';
import ReactTooltip from 'react-tooltip';
import { styles } from '../styles';
import { PaginationList } from './PaginationList';

export function CodesList() {
    const dispatch = useDispatch();
    const loaderList = useSelector(fromStore.loaderPhoneCodesSelector);
    const codes = useSelector(fromStore.phoneCodesListSelector);

    useEffect(() => ReactTooltip.rebuild(), [codes, loaderList]);

    useEffect(() => {
        dispatch(fetchPhoneCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{ padding: 0 }}>
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    <div
                        style={{ position: 'relative', marginBottom: 0 }}
                        className={
                            cn('kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded', {
                                'kt-datatable--error': isEmpty(codes)
                            })
                        }>
                        <div className="alert alert-warning" style={{ margin: '20px' }}>
                            <strong style={{ marginRight: '5px' }}>Внимание!</strong> Таблица отправленых кодов очищается каждый день в 03:00
                        </div>
                        {loaderList && <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>}
                        <table className="kt-datatable__table">
                            <TableHeader />
                                <tbody className="kt-datatable__body">
                                    {
                                        loaderList
                                            ? null
                                            : isEmpty(codes)
                                                ? <tr><td colSpan={8}><span className="kt-datatable--error">Неподтвержденных кодов пока нет</span></td></tr>
                                                : codes.map((item) => <ItemRow key={item.id} item={item} />)
                                    }
                                </tbody>
                        </table>
                        {codes.length > 0 && <PaginationList />}
                    </div>
                </div>
            </div>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </div>
    );
}