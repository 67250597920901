import { createAction } from 'redux-actions';
import { urls } from '../constants/app';
import { makeActionType } from '../utils';
import { createFormData } from '../utils/common';
import { resetError, setError } from './errors';
import request from './utils';

const namespace = 'SLIDERS';

export const setSliders = createAction(makeActionType(namespace, ['SET']));
export const setSlider = createAction(makeActionType(namespace, ['SET', 'GOOD']));
export const addSlidersList = createAction(makeActionType(namespace, ['ADD', 'GOOD']));
export const deleteSliderAction = createAction(makeActionType(namespace, ['DELETE', 'GOOD']));
export const setPagingSliders = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingSliders = createAction(makeActionType(namespace, ['SET', 'SORTING']));
export const setCountSliders = createAction(makeActionType(namespace, ['SET', 'COUNT']));
export const setDetailSlider = createAction(makeActionType(namespace, ['SET', 'DETAIL']));
export const setActiveModalSlidersAction = createAction(makeActionType('LAYOUT', ['SET', 'MODAL']));

export function fetchSliders(withoutLoader = false) {
    return async (dispatch) => {
        !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'sliders', value: true });
        dispatch(resetError('sliders'));
        try {
            const sliders = await request({ method: 'get', url: urls.sliders, params: { _sort: 'priority:DESC' } });
            dispatch(setSliders(sliders))
            dispatch(setCountSliders(sliders.length));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'sliders', value: false });
        } catch(error) {
            dispatch(setError(error, 'sliders'));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'sliders', value: false });
        }
    }
}

export function addSlider(values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'addSlider', value: true });
        const data = createFormData(values);
        try {
            const slider = await request({ method: 'post', url: urls.sliders, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (slider) {
                dispatch({ type: 'SET_LOADER', field: 'addSlider', value: false });
                dispatch(addSlidersList(slider));
                return slider.id;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'addSlider'));
            dispatch({ type: 'SET_LOADER', field: 'addSlider', value: false });
            return null;
        }
    }
}

export function updateSlider(id, values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'updateSlider', value: id });
        dispatch(resetError('sliders'));
        const data = createFormData(values);
        try {
            const slider = await request({ method: 'put', url: `${urls.sliders}/${id}`, data, headers: { "Content-Type": "multipart/form-data" }, });
            dispatch(setSlider(slider));
            dispatch({ type: 'SET_LOADER', field: 'updateSlider', value: null });
            return slider.id;
        } catch(error) {
            dispatch(setError(error, 'sliders'));
            dispatch({ type: 'SET_LOADER', field: 'updateSlider', value: null });
            return null;
        }
    }
}

export function deleteSlider(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteSlider', value: id });
        dispatch(resetError('sliders'));
        try {
            await request({ method: 'delete', url: `${urls.sliders}/${id}` });
            dispatch(deleteSliderAction(id));
            dispatch({ type: 'SET_LOADER', field: 'deleteSlider', value: null });
        } catch(error) {
            dispatch(setError({ status: 507 }, 'sliders'));
            dispatch({ type: 'SET_LOADER', field: 'deleteSlider', value: null });
        }
    }
}
