import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setActiveModalAction } from '../../../action';
import { fromStore } from '../../../selectors';
import { MEASURES } from '../constants';

export function ParamsGood() {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);

    const onOpenModalEditGood = (e) => {
        e.stopPropagation();
        const goodId = detail.id || 'new';
        dispatch(setActiveModalAction({ field: 'good', value: goodId }));
    };

    return (
        <div className="kt-portlet">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                        Параметры товара
                    </h3>
                </div>
            </div>
            <div className="kt-form kt-form--label-right">
                <div className="kt-portlet__body" style={{ minHeight: '350px' }}>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Категория:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.category.title}</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Цена:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.price} ₽</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Старая цена:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.oldPrice ? `${detail.oldPrice} ₽` : '-'}</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Вес/Объем:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.weight} {MEASURES[detail.measure]}</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Острое:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.hot ? 'Да' : 'Нет'}</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Рекомендуемое:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.recomended ? 'Да' : 'Нет'}</span>
                        </div>
                    </div>
                    <div className="form-group form-group-xs row">
                        <label className="col-6 col-form-label">Подарок:</label>
                        <div className="col-6">
                            <span className="form-control-plaintext kt-font-bolder">{detail.gift ? 'Да' : 'Нет'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="kt-portlet__foot">
                <div className="kt-form__actions kt-space-between">
                    <button onClick={onOpenModalEditGood} className="btn btn-label-brand btn-sm btn-bold">Редактировать</button>
                </div>
            </div>
        </div>
    );
}