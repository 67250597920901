import React from 'react';
import Content from '../../components/layout/Content';
import BackButton from '../../components/utils/BackButton';

export default function AddGoodPage() {
    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <BackButton />
                    <h3 className="kt-subheader__title">Добавление товара</h3>
                </div>
            </div>
            </div>
        );
    };
    return (
        <Content>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                Content
            </div>
        </Content>
    );
}