import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import { getImageUrl } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { deleteSlider, setActiveModalAction, updateSlider } from '../../../action';
import swal from 'sweetalert';

export const ItemRow = ({ item }) => {
    const dispatch = useDispatch();
    const loaderUpdateSlider = useSelector(fromStore.loaderUpdateSliderSelector);
    const loaderDeleteSlider = useSelector(fromStore.loaderDeleteSliderSelector);
    const loaderUpdate = loaderUpdateSlider === item.id;
    const loadertDelete = loaderDeleteSlider === item.id;

    const onOpenModalGood = (id) => (e) => {
        e.stopPropagation();
        const sliderId = id || 'new';
        dispatch(setActiveModalAction({ field: 'slider', value: sliderId }));
    }

    const onToogleActiveSlider = () => {
        const data = { active: !item.active };
        dispatch(updateSlider(item.id, data))
    };

    const onDeleteSlider = () => {
        swal({
            title: `Удаление слайдера`,
            text: `Вы действительно хотите удалить ${item.title}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteSlider(item.id));
            }
        });
    }

    return (
        <tr key={`${item.id}_slider`} className="kt-datatable__row">
            <td width="80px" className="kt-datatable__cell"><span>{item.id}</span></td>
            <td width="220px" className="kt-datatable__cell">
                <img width="200px" alt={item.name} src={getImageUrl(item.image)} />
            </td>
            <td width="300px" className="kt-datatable__cell"><span>{item.title}</span></td>
            <td width="auto" className="kt-datatable__cell"><span>{item.description}</span></td>
            <td align='right' width="180px" className="kt-datatable__cell">
                <button
                    onClick={onToogleActiveSlider}
                    style={{ marginRight: '3px' }}
                    data-tip={item.active ? 'Выключить' : 'Включить'}
                    disabled={loaderUpdate || loadertDelete}
                    className={cn("btn btn-sm btn-icon btn-icon-md", {
                        'btn-danger': !item.active,
                        'btn-success': item.active,
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                    })}>
                    {!loaderUpdate && <i className="la la-power-off"></i>}
                </button>
                <button onClick={onOpenModalGood(item.id)} disabled={loaderUpdate || loadertDelete} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                <button
                    onClick={onDeleteSlider}
                    disabled={loaderUpdate || loadertDelete}
                    data-tip="Удалить" 
                    className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': loadertDelete
                    })}>
                        {!loadertDelete && <i className="la la-trash"></i>}
                    </button>
            </td>
        </tr>
    );
}
