import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { fetchCategories, setActiveCategory, setActiveModalAction } from '../../../action';
import Loader from '../../../components/utils/Loader';
import { fromStore } from '../../../selectors';
import { getImageUrl } from '../../../utils/common';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { styles } from '../styles';

export function CategoryList() {
    const dispatch = useDispatch();
    const loading = useSelector(fromStore.loaderCategoriesSelector);
    const categories = useSelector(fromStore.categoryListSelector);
    const activeCategory = useSelector(fromStore.activeCategorySelector);
    
    const onSelectCategory = (id) => () => {
        dispatch(setActiveCategory(id));
        document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    };

    const onOpenModalCategory = (id) => (e) => {
        e.stopPropagation();
        const categoryId = id || 'new';
        dispatch(setActiveModalAction({ field: 'category', value: categoryId }));
    };

    useEffect(() => {
        isEmpty(categories) && dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (loading) {
        return <div style={styles.loading}><Loader text='Загружаю категории'/></div>;
    }

    return (
        <>
            <div className="kt-todo__nav">
                <ul className="kt-nav" style={{ padding: 0 }}>
                    {isEmpty(categories) ? <li>Категорий нет</li> : categories.map((category) => (
                         <li
                            onClick={onSelectCategory(category.id)}
                            key={`${category.id}_category`}
                            className={cn('kt-nav__item', { 'kt-nav__item--active': activeCategory === category.id, 'inner-cat': !!category.inner })}>
                            <span className="kt-nav__link" data-action="list">
                                <img width="30px" style={{ marginRight: '10px' }} src={getImageUrl(category.imagePrev)} alt=""/>
                                <span className="kt-nav__link-text">{category.title}</span>
                                <button data-tip="Редактировать"onClick={onOpenModalCategory(category.id)} className="btn btn-sm btn-clean btn-icon btn-icon-lg">
                                    <i className="la la-edit"></i>
                                </button>
                            </span>
                            
                        </li>
                    ))}
                </ul>
            </div>
            <button onClick={onOpenModalCategory()} className="btn btn-brand btn-elevate btn-icon-sm" style={{ marginTop: '20px' }}>
                <i className="la la-plus"></i>
                Добавить категорию
            </button>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </>
    );
}