import { loadersSelector, documentationSelector } from './root';
import { propNameSelector } from '../utils';

export const documentationListSelector = propNameSelector(documentationSelector, 'list');
export const documentationDetailSelector = propNameSelector(documentationSelector, 'detail');
export const pagingDocumentationSelector = propNameSelector(documentationSelector, 'paging');
export const sortingDocumentationSelector = propNameSelector(documentationSelector, 'sorting');
export const filtersDocumentationSelector = propNameSelector(documentationSelector, 'filters');
export const countDocumentationSelector = propNameSelector(documentationSelector, 'count');
export const loaderDocumentationSelector = propNameSelector(loadersSelector, 'documentation');
export const loaderAddDocumentationSelector = propNameSelector(loadersSelector, 'documentationAdd');