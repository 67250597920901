import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteKassaAccount, setActiveModalAction } from '../../../action';
import swal from 'sweetalert';

export const ItemRow = ({ item }) => {
    const dispatch = useDispatch();
    const loaderUpdateKassa = useSelector(fromStore.loaderUpdateKassaSelector);
    const loaderDeleteKassa = useSelector(fromStore.loaderDeleteKassaSelector);
    const loaderUpdate = loaderUpdateKassa === item.id;
    const loadertDelete = loaderDeleteKassa === item.id;

    const onOpenModal = (id) => (e) => {
        e.stopPropagation();
        const accountId = id || 'new';
        dispatch(setActiveModalAction({ field: 'yookassa', value: accountId }));
    }

    const onDeleteAccount = () => {
        swal({
            title: `Удаление аккаунта`,
            text: `Вы действительно хотите удалить ${item.name}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteKassaAccount(item.id));
            }
        });
    }

    return (
        <tr key={`${item.id}_slider`} className="kt-datatable__row">
            <td width="80px" className="kt-datatable__cell"><span>{item.id}</span></td>
            <td width="220px" className="kt-datatable__cell">
                <span>{item.name}</span>
            </td>
            <td width="auto" className="kt-datatable__cell"><span>{item.secret}</span></td>
            <td width="200px" className="kt-datatable__cell"><span>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span></td>
            <td align='right' width="180px" className="kt-datatable__cell">
                <button onClick={onOpenModal(item.id)} disabled={loaderUpdate || loadertDelete} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                <button
                    onClick={onDeleteAccount}
                    disabled={loaderUpdate || loadertDelete}
                    data-tip="Удалить" 
                    className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': loadertDelete
                    })}>
                        {!loadertDelete && <i className="la la-trash"></i>}
                    </button>
            </td>
        </tr>
    );
}
