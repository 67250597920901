import React, { useState } from 'react';
import { withRouter  } from 'react-router-dom';
import Input from '../../components/utils/Input';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch } from 'react-redux';
import { addDocumentation } from '../../action';

function AddDocumentation({ history }) {
    const dispatch = useDispatch();
    const [addData, setAddData] = useState({});
    const loading = useSelector(fromStore.loaderAddDocumentationSelector)
    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Добавить документ</h3>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => history.push('/documentation')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    const isValidDocumentation = () => {
        const { title, description, file} = addData;
        if (title && description && file) return true;
        return false;
    }

    const setData = (field) => (e) => {
        const data = {...addData};
        data[field] = e.target.value;
        setAddData(data);
    }

    const setFile = (e) => {
       const data = {...addData};
       data.file = e.target.files[0];
       setAddData(data);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const result = dispatch(addDocumentation(addData));
        if (result) {
            history.push('/documentation');
        }
    }
    
    return (
        <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor'>
            { renderSubHeader() }
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                
            <div className="kt-portlet">
                <form onSubmit={onSubmit} className="kt-form">
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Заголовок"
                                    required={true}
                                    onChange={setData('title')}
                                    value={addData.title}
                                    placeholder="Заголовок..."
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '25px' }}>
                            <div className="col-md-12">
                                <label>Файл *</label>
                                <div>
                                    <input type="file" onChange={setFile}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Краткое описание"
                                    required={true}
                                    onChange={setData('description')}
                                    value={addData.description}
                                    placeholder="Краткое описание..."
                                />
                            </div>
                        </div>
                    </div>
                    <ErrorContainer field="addDocumentation" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <button
                                disabled={!isValidDocumentation() || loading}
                                type="submit"
                                className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loading })}>
                                Добавить
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default withRouter(AddDocumentation);