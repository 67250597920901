import React, {Component, Fragment} from 'react';
import { ordersPages } from '../constants/page';
import {Route} from 'react-router-dom';
import Content from '../components/layout/Content';

class OrdersPage extends Component {
    render() {
        return (
            <Fragment>
                { 
                    ordersPages.map(page => (
                        <Route
                            key={page.name}
                            exact
                            path={page.path}
                            render={props => {
                                if (props.match.params.id && !/\d+/.test(props.match.params.id)) {
                                      return null;
                                }
                                   
                                const Component = page.component;
                                return (
                                    <Content subheader={true} title={page.title} fit={page.fit}>
                                        <Component {...props} isArchive={page.name === 'archive' || page.name === 'viewArchive'}/>
                                    </Content>
                                );
                            }}
                        />
                    )) 
                }
            </Fragment>
        )
    }
}

export default OrdersPage;