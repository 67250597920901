import { combineReducers } from 'redux';
import list from './list';
import paging from './paging';
import sorting from './sorting';
import count from './count';

export default combineReducers({
  list,
  paging,
  sorting,
  count,
});
