import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setAddCity, addCity } from '../../action/handbook';
import cn from 'classnames';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl } from 'react-yandex-maps';

class AddCity extends Component {
    componentWillUnmount() {
        this.props.setAddCity({});
    }
    
    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Добавить город</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push('/handbook/cities')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidCity() {
        const { name, article, coord, deliveryTime, deliveryWork, deliveryCost, freeDelivery, gift, minOrder, frontpad, idFilial, idFrontpadDelivery, idFrontpadPick, idStatusCancel, idStatusDone} = this.props.addForm;
        if (frontpad && (!idFilial || !idFrontpadDelivery || !idFrontpadPick || !idStatusCancel || !idStatusDone)) return false;
        if (name && article && coord && deliveryTime && deliveryWork && deliveryCost && freeDelivery && gift && minOrder) return true;
        
        return false;
    }

    setCity = (field) => (e) => {
        const data = {...this.props.addForm};
        data[field] = e.target.value;
        this.props.setAddCity(data);
    }

    setCoord = (value) => {
        const data = {...this.props.addForm};
        data['coord'] = value;
        this.props.setAddCity(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.addCity(this.props.addForm).then(result => {
            if (result) {
                this.props.history.push('/handbook/cities');
            }
        });
    }
    
    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    render() {
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    
                <div className="kt-portlet">
                    <form onSubmit={this.onSubmit} className="kt-form">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Название города"
                                        required={true}
                                        onChange={this.setCity('name')}
                                        value={this.props.addForm.name}
                                        placeholder="Город..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label="ID города на сайте"
                                        required={true}
                                        onChange={this.setCity('article')}
                                        value={this.props.addForm.article}
                                        placeholder="ID города..."
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        required={true}
                                        label="Координаты"
                                        onChange={this.setCity('coord')}
                                        value={this.props.addForm.coord}
                                        placeholder="Координаты..."
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <YMaps
                                        style={{ padding: '25px ', marginTop: -30 }}
                                        query={{
                                            apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                                        }}
                                        >
                                        <Map
                                            
                                            width="100%"
                                            defaultState={{ center: [47.222127, 39.720367], zoom: 10, controls: [] }}
                                        >
                                        <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '30px', marginBottom: '10px' }}>
                                <div className="col-md-6">
                                    <h5>
                                        Настройки Frontpad
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        label="Frontpad токен"
                                        onChange={this.setCity('frontpad')}
                                        value={this.props.addForm.frontpad}
                                        placeholder="Токен..."
                                    />
                                </div>
                            </div>
                            {
                                this.props.addForm.frontpad && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID филиала во Frontpad"
                                                    onChange={this.setCity('idFilial')}
                                                    value={this.props.addForm.idFilial}
                                                    placeholder="ID филиала..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID доставки во Frontpad"
                                                    onChange={this.setCity('idFrontpadDelivery')}
                                                    value={this.props.addForm.idFrontpadDelivery}
                                                    placeholder="ID доставки..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID самовывоза во Frontpad"
                                                    onChange={this.setCity('idFrontpadPick')}
                                                    value={this.props.addForm.idFrontpadPick}
                                                    placeholder="ID самовывоза..."
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса Выполнен"
                                                    onChange={this.setCity('idStatusDone')}
                                                    value={this.props.addForm.idStatusDone}
                                                    placeholder="ID статуса..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса Списан"
                                                    onChange={this.setCity('idStatusCancel')}
                                                    value={this.props.addForm.idStatusCancel}
                                                    placeholder="ID статуса..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса в обработке"
                                                    onChange={this.setCity('idStatusProgress')}
                                                    value={this.props.addForm.idStatusProgress}
                                                    placeholder="ID в обработке..."
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Приложение>"
                                                onChange={this.setCity('idTagApp')}
                                                value={this.props.addForm.idTagApp}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Сайт>"
                                                onChange={this.setCity('idTagSite')}
                                                value={this.props.addForm.idTagSite}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Доставка>"
                                                onChange={this.setCity('idTagDelivery')}
                                                value={this.props.addForm.idTagDelivery}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Самовывоз>"
                                                onChange={this.setCity('idTagSam')}
                                                value={this.props.addForm.idTagSam}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Оплата терминал>"
                                                onChange={this.setCity('idTagTerminal')}
                                                value={this.props.addForm.idTagTerminal}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Оплата наличными>"
                                                onChange={this.setCity('idTagCash')}
                                                value={this.props.addForm.idTagCash}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Сдача с 1000>"
                                                onChange={this.setCity('idTagCash1000')}
                                                value={this.props.addForm.idTagCash1000}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Сдача с 1500>"
                                                onChange={this.setCity('idTagCash1500')}
                                                value={this.props.addForm.idTagCash1500}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Сдача с 2000>"
                                                onChange={this.setCity('idTagCash2000')}
                                                value={this.props.addForm.idTagCash2000}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Сдача с 5000>"
                                                onChange={this.setCity('idTagCash5000')}
                                                value={this.props.addForm.idTagCash5000}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Автораспределение>"
                                                onChange={this.setCity('idTagAutoDistribution')}
                                                value={this.props.addForm.idTagAutoDistribution}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                label="ID метки <Онлайн-Оплата>"
                                                onChange={this.setCity('idTagOnlinePay')}
                                                value={this.props.addForm.idTagOnlinePay}
                                                placeholder="ID метки..."
                                            />
                                        </div>
                                    </div>
                                    </div>
                                )
                            }
                            
                            <div className="row" style={{ marginTop: '0px', marginBottom: '10px' }}>
                                <div className="col-md-6">
                                    <h5>
                                        Настройки по умолчаю для филиалов
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Время доставки"
                                        onChange={this.setCity('deliveryTime')}
                                        value={this.props.addForm.deliveryTime}
                                        placeholder="Время доставки..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Время работы доставки"
                                        onChange={this.setCity('deliveryWork')}
                                        value={this.props.addForm.deliveryWork}
                                        placeholder="Время работы..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Стоимость доставки"
                                        onChange={this.setCity('deliveryCost')}
                                        value={this.props.addForm.deliveryCost}
                                        placeholder="Стоимость доставки..."
                                        type="number"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Бесплатная доставка от"
                                        onChange={this.setCity('freeDelivery')}
                                        value={this.props.addForm.freeDelivery}
                                        placeholder="Бесплатная доставка от..."
                                        type="number"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Подарок от суммы"
                                        onChange={this.setCity('gift')}
                                        value={this.props.addForm.gift}
                                        placeholder="Подарок от суммы..."
                                        type="number"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        required={true}
                                        label="Минимальная сумма для доставки"
                                        onChange={this.setCity('minOrder')}
                                        value={this.props.addForm.minOrder}
                                        placeholder="Минимальная сумма..."
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <ErrorContainer field="addCity" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidCity() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.loading })}>
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    addForm: state.handbook.add,
    loading: state.loaders.addCity
});

const mapDispatchToProps = {
    setAddCity: (data) => setAddCity(data),
    addCity: (data) => addCity(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCity);