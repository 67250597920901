import { loadersSelector, slidersSelector } from './root';
import { propNameSelector } from '../utils';
import { createSelector } from 'reselect';

export const slidersListSelector = propNameSelector(slidersSelector, 'list');
export const pagingSlidersSelector = propNameSelector(slidersSelector, 'paging');
export const sortingSlidersSelector = propNameSelector(slidersSelector, 'sorting');
export const countSlidersSelector = propNameSelector(slidersSelector, 'count');
export const loaderSlidersSelector = propNameSelector(loadersSelector, 'sliders');
export const loaderAddSliderSelector = propNameSelector(loadersSelector, 'addSlider');
export const loaderUpdateSliderSelector = propNameSelector(loadersSelector, 'updateSlider');
export const loaderDeleteSliderSelector = propNameSelector(loadersSelector, 'deleteSlider');

export const sliderById = (id) => createSelector(
    slidersListSelector,
    (items) => {
        return items.find((item) => item.id === id);
    },
);