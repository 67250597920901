import moment from 'moment';

const currentDate = new Date();

const initialState = {
    refferers: {
        result: null,
        filters: {
            city: null,
            createdStartDate: null,
            createdEndDate: null
        },
        loading: false,
        error: null
    },
    popGoods: {
        result: null,
        filters: {
            city: null,
            createdStartDate: moment(currentDate).subtract({ days: 30 }).toDate(),
            createdEndDate: currentDate
        },
        loading: false,
        error: null
    },
    orders: {
        result: null,
        filters: {
            city: null,
            createdStartDate: moment(currentDate).subtract({ days: 15 }).toDate(),
            createdEndDate: currentDate
        },
        loading: false,
        error: null
    },
    ordersOfHead: {
        result: null,
    },
    promocode: {
        result: null,
        filters: {
            city: null,
            createdStartDate: moment(currentDate).subtract({ days: 15 }).toDate(),
            createdEndDate: currentDate
        },
        loading: false,
        error: null
    },
    clients: {
        result: null,
        filters: {
            city: null,
            createdStartDate: moment(currentDate).subtract({ days: 15 }).toDate(),
            createdEndDate: currentDate
        },
        loading: false,
        error: null
    },
    pointslogs: {
        result: null,
        filters: {
            city: null,
            createdStartDate: moment(currentDate).subtract({ days: 15 }).toDate(),
            createdEndDate: currentDate
        },
        loading: false,
        error: null
    }
};

export default function statistics(state = initialState, action) {
  switch(action.type) {
        case 'SET_STATISTICS': {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    result: action.result
                }
            };
        }
        case 'SET_STATISTICS_FILTERS': {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    filters: action.filters
                }
            };
        }
        case 'RESET_STATISTICS_FILTERS': {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    filters: initialState[action.name].filters
                }
            };
        }
        case 'SET_STATISTICS_LOADER': {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    loading: action.loading
                }
            }
        }
        case 'SET_STATISTICS_ERROR': {
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    error: action.error
                }
            }
        }
        default: return state;
    }
}