const initialState = {
    goods: [],
    phone: null,
    area: null,
    street: null,
    house: null,
    entrance: null,
    floor: null,
    room: null,
    comment: null,    
    point: null,
    zones: null,
    goodPrice: 0,
    salePrice: 0,
    sale: null,
    totalPrice: 0,
    unit: null,
    deliveryMethod: 'delivery',
    deliveryPrice: 0,
    deliveryArticle: null,
    payment: null,
    changeCash: null,
    source: 'crm',
    isAggPrice: false
};

export default function neworders(state = initialState, action) {
  switch(action.type) {
    case 'ADD_POSITION_NEW_ORDER': {
        if ([action.setting.pizza, action.setting.wok].includes(action.good.category.id))
        {
            const dataGoods = { ...action.good, count: 1, index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1}
            return {
                ...state,
                goods: [...state.goods, dataGoods],
            };
        }

        if(action.good.category.id === action.setting.mainWok)
        {
            const dataGoods = { 
                id: action.good.id, 
                isWok: action.good.isWok,
                category: action.good.category,
                count: 1, 
                wokData:{
                    id: action.good.id + (state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1),
                    main: {
                        id: action.good.id, 
                        article: action.good.article, 
                        title: action.good.name, 
                        price: action.good.price, 
                        oldPrice: action.good.oldPrice,                        
                    },
                    meat: {},
                    topping: {},
                    souce: {},
                    oldPrice: action.good.oldPrice ? action.good.oldPrice : 0,
                    price: action.good.price ? action.good.price : 0, 
                },                 
                index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1
            }
            return {
                ...state,
                goods: [...state.goods, dataGoods],
            };
        }

        if(state.goods.find((el) => el.id === action.good.id))
        {
            const updatedGoods = state.goods.map(item =>
                item.id === action.good.id ? { ...item, count: parseInt(item.count) + 1 } : item
            );
            return {
                ...state,
                goods: updatedGoods,
            };
        }
        else
        {
            const dataGoods = { ...action.good, count: 1, index: state.goods.length > 0 ? state.goods[state.goods.length - 1].index + 1 : 1}
            return {
                ...state,
                goods: [...state.goods, dataGoods],
            };
        }
    }

    case 'PLUS_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, count: parseInt(item.count) + 1 } : item
        );
        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'MINUS_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, count: parseInt(item.count) - 1 } : item
        );
        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'DELETE_POSITION_NEW_ORDER': {
        return {
            ...state,
            goods: state.goods.filter((item) => item.index !== action.index),
        };
    }

    case 'CHANGE_COUNT_POSITION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, count: action.value } : item
        );

        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'CHANGE_MODIFICATION_NEW_ORDER': {
        const updatedGoods = state.goods.map(item =>
            item.index === action.index ? { ...item, isMod: action.value } : item
        );

        return {
            ...state,
            goods: updatedGoods,
        };
    }

    case 'ADD_ADDITIVES_NEW_ORDER': {
        switch(action.good.category.id){
            case(action.settings.mainWok): {       
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                    item.wokData.main.id !== action.good.id
                    ?
                    {
                        ...item,
                        count: item.count,
                        wokData: {
                            id: item.wokData.id,                            
                            main: {
                                article: action.good.article,
                                id: action.good.id,
                                title: action.good.name,
                                price: action.good.price,
                                oldPrice: action.good.oldPrice                                
                            },  
                            meat: {
                                ...item.wokData.meat,                                            
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,
                            }
            
                        }
                    }
                    :
                    {
                        ...item,
                        count: parseInt(item.count) + 1,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            };

            case(action.settings.meatWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                        !item.wokData.meat[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,
                                    [action.good.id]: {
                                        count: 1,
                                        article: action.good.article,
                                        data: {
                                            id: action.good.id,
                                            title: action.good.name,
                                            price: action.good.price,
                                            oldPrice: action.good.oldPrice
                                        }
                                    }                
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                }
                
                            }
                        }
                        :
                        {
                            
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,
                                    [action.good.id]: {
                                        ...item.wokData.meat[action.good.id],
                                        count: parseInt(item.wokData.meat[action.good.id].count) + 1
                                    }
                                },
                                topping: {
                                    ...item.wokData.topping,                                    
                                },
                                souce: {
                                    ...item.wokData.souce,                                    
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case(action.settings.toppingWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                    !item.wokData.topping[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                    [action.good.id]: {
                                        count: 1,
                                        article: action.good.article,
                                        data: {
                                            id: action.good.id,
                                            title: action.good.name,
                                            price: action.good.price,
                                            oldPrice: action.good.oldPrice
                                        }
                                    }      
                                },
                                souce: {
                                    ...item.wokData.souce,
                                }
                
                            }
                        }
                        :
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                    [action.good.id]: {
                                        ...item.wokData.topping[action.good.id],
                                        count: parseInt(item.wokData.topping[action.good.id].count) + 1
                                    }
                                },
                                souce: {
                                    ...item.wokData.souce,                                    
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case(action.settings.souceWok): {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index
                    ? 
                        !item.wokData.souce[action.good.id]
                        ?
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                    [action.good.id]: {
                                        count: 1,
                                        article: action.good.article,
                                        data: {
                                            id: action.good.id,
                                            title: action.good.name,
                                            price: action.good.price,
                                            oldPrice: action.good.oldPrice
                                        }
                                    }   
                                }            
                            }
                        }
                        :
                        {
                            ...item,
                            wokData: {
                                id: item.wokData.id,
                                main: {
                                    ...item.wokData.main,                                            
                                },
                                meat: {
                                    ...item.wokData.meat,                                          
                                },
                                topping: {
                                    ...item.wokData.topping,
                                },
                                souce: {
                                    ...item.wokData.souce,
                                    [action.good.id]: {
                                        ...item.wokData.souce[action.good.id],
                                        count: parseInt(item.wokData.souce[action.good.id].count) + 1
                                    }
                                }           
                            }
                        }
                    : 
                    item
                );

                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }
    
    case 'PLUS_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        count: parseInt(item.count) + 1,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(item.wokData.meat[action.idAdditives].count) + 1
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(item.wokData.topping[action.idAdditives].count) + 1
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt(item.wokData.souce[action.idAdditives].count) + 1
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }        
    }

    case 'MINUS_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        count: parseInt(item.count) - 1,
                        wokData: {
                            id: item.wokData.id,                            
                            main: {
                                ...item.wokData.main,                                                                         
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(item.wokData.meat[action.idAdditives].count) - 1
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(item.wokData.topping[action.idAdditives].count) - 1
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt(item.wokData.souce[action.idAdditives].count) - 1
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }

    case 'DELETE_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: Object.values(item.wokData.meat).filter((el) => 
                                el.data.id !== action.idAdditives 
                            ),                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: Object.values(item.wokData.topping).filter((el) => 
                                el.data.id !== action.idAdditives 
                            ), 
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: Object.values(item.wokData.souce).filter((el) => 
                                el.data.id !== action.idAdditives 
                            )    
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }        
    }

    case 'CHANGE_COUNT_ADDITIVES_NEW_ORDER': {
        switch(action.subtype) {
            case 'main': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        count: parseInt(action.value),
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,                                                             
                            },
                            meat: {
                                ...item.wokData.meat,                                          
                            },
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'meat': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,
                                [action.idAdditives]: {
                                    ...item.wokData.meat[action.idAdditives],
                                    count: parseInt(action.value)
                                }
                            },                                        
                            topping: {
                                ...item.wokData.topping,
                            },
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            case 'topping': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,
                                [action.idAdditives]: {
                                    ...item.wokData.topping[action.idAdditives],
                                    count: parseInt(action.value) 
                                }
                            },      
                            souce: {
                                ...item.wokData.souce,                                
                            }            
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }
            
            case 'souce': {
                const updatedGoods = state.goods.map(item =>
                    item.index === action.index 
                    ? 
                    { 
                        ...item,
                        wokData: {
                            id: item.wokData.id,
                            main: {
                                ...item.wokData.main,       
                            },
                            meat: {
                                ...item.wokData.meat,                                
                            },                               
                            topping: {
                                ...item.wokData.topping,                                
                            },   
                            souce: {
                                ...item.wokData.souce,
                                [action.idAdditives]: {
                                    ...item.wokData.souce[action.idAdditives],
                                    count: parseInt(action.value)
                                }
                            }        
                        }
                    } 
                    : 
                    item
                );
                return {
                    ...state,
                    goods: updatedGoods,
                };
            }

            default: return state
        }
    }

    case 'CALCULATE_WOK_PRICE': {
        const updatedGoods = state.goods.map((item) => 
        item.index === action.index
        ?
            item.wokData
            ?
            { 
                ...item,
                wokData: {
                    id: item.wokData.id,
                    price: parseFloat(item.wokData.main.price * item.count +
                    Object.values(item.wokData.meat).reduce((Sum, el) => el.data.price ? Sum + el.data.price * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.topping).reduce((Sum, el) => el.data.price ? Sum + el.data.price * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.souce).reduce((Sum, el) => el.data.price ? Sum + el.data.price * el.count  : Sum + 0, 0)).toFixed(2),
                    oldPrice: parseFloat(item.wokData.main.oldPrice * item.count +
                    Object.values(item.wokData.meat).reduce((Sum, el) => el.data.oldPrice ? Sum + el.data.oldPrice * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.topping).reduce((Sum, el) => el.data.oldPrice ? Sum + el.data.oldPrice * el.count : Sum + 0, 0) + 
                    Object.values(item.wokData.souce).reduce((Sum, el) => el.data.oldPrice ? Sum + el.data.oldPrice * el.count : Sum + 0, 0)).toFixed(2),
                    main: {
                        ...item.wokData.main,       
                    },
                    meat: {
                        ...item.wokData.meat,                                          
                    },
                    topping: {
                        ...item.wokData.topping,
                    },
                    souce: {
                        ...item.wokData.souce,                                
                    }            
                }
            } 
            :
            item
        :
        item
    )

    return {
        ...state,
        goods: updatedGoods,
    };
    }

    

    case 'CHANGE_UNIT_NEW_ORDER': {
        return {
            ...state,
            unit: action.value 
        };
    }

    case 'SET_SALE_PERCENT_NEW_ORDER': {
        if(action.value !== state.sale)
            return {
                ...state,
                sale: action.value,
                salePrice: 0
            };

        return {
            ...state,
            sale: null,
            salePrice: 0
        };
    }

    case 'SET_SALE_SUM_NEW_ORDER': {
        if(parseFloat(action.value) > parseFloat(state.goodPrice))
            return {
                ...state,
                sale: null,
                salePrice: parseFloat(state.goodPrice)
            };
            
        return {
            ...state,
            sale: null,
            salePrice: parseFloat(action.value)
        };
    }

    case 'CALCULATION_TOTALPRICE_NEW_ORDER': {
        let saleCheck = state.salePrice
        const GoodPrice = (state.goods.reduce((Sum, item) => 
            !item.wokData
            ?
                !state.isAggPrice
                ?
                    !item.isMod
                    ?
                    Sum + (parseFloat(item.price || 0) * parseInt(item.count))
                    :
                    Sum + ((parseFloat(item.price || 0) + parseFloat(item.isMod.price || 0)) * parseInt(item.count))
                :
                    !item.isMod
                    ?
                    Sum + (parseFloat(item.oldPrice || 0) * parseInt(item.count))
                    :
                    Sum + ((parseFloat(item.oldPrice || 0) + parseFloat(item.isMod.oldPrice || 0)) * parseInt(item.count))
            :
                !state.isAggPrice
                ?
                Sum + parseFloat(item.wokData.price)
                :
                Sum + parseFloat(item.wokData.oldPrice || 0), 0) + state.deliveryPrice).toFixed(2)
        
        if(state.sale !== null)
            saleCheck = parseFloat(GoodPrice * state.sale).toFixed(2)

        return { 
            ...state,
            goodPrice: GoodPrice,
            totalPrice: parseFloat(GoodPrice - saleCheck).toFixed(2),
            salePrice: parseFloat(saleCheck).toFixed(2)
        };
    }

    case 'CHANGE_POINT_NEW_ORDER': {
        return { 
            ...state,
            point: action.value,
            deliveryArticle: null,
            deliveryPrice: 0
            }
    }

    case 'ADD_DELIVERY_PRICED_NEW_ORDER': {
        return {
            ...state,
            deliveryArticle: action.article,
            deliveryPrice: action.price
        }
    }

    case 'SET_ZONES_NEW_ORDER': {
        return { 
            ...state,
            zones: action.value
            }
    }

    case 'CHANGE_DELIVERY_METHOD_NEW_ORDER': {
        return { 
            ...state,
            deliveryMethod: action.value
            }
    }

    case 'CHANGE_CLIENT_INFORMATION_NEW_ORDER': {
        switch(action.subtype){
            case 'area': {
                return { 
                    ...state,
                    area: action.value
                    }
            }
            case 'street': {
                return { 
                    ...state,
                    street: action.value
                    }
            }
            case 'house': {
                return { 
                    ...state,
                    house: action.value
                    }
            }
            case 'entrance': {
                return { 
                    ...state,
                    entrance: action.value
                    }
            }
            case 'floor': {
                return { 
                    ...state,
                    floor: action.value
                    }
            }
            case 'room': {
                return { 
                    ...state,
                    room: action.value
                    }
            }
            case 'comment': {
                return { 
                    ...state,
                    comment: action.value
                    }
            }       
            case 'phone': {
                let validPhone = String(action.value).substring(0, 11);
                return { 
                    ...state,
                    phone: parseInt(validPhone)
                    }
            }         
            default: return state;
        }            
    }

    case 'CHANGE_AGG_PRICE_NEW_ORDER': {
        return { 
            ...state,
            isAggPrice: action.value
            }
    }

    case 'CHANGE_PAYMENT_NEW_ORDER': {
        if(action.value !== state.payment)
        return {
            ...state,
            payment: action.value,
            changeCash: null
            }

        return {
            ...state,
            payment: null,
            changeCash: null 
            }
    }

    case 'CHANGE_CHANGECASH_NEW_ORDER': {
        if(action.value !== state.changeCash)
        return {
            ...state,
            changeCash: action.value            
            }

        return {
            ...state,
            changeCash: null         
            }
    }

    case 'CLEAR_NEW_ORDER': {
        return {
            ...state,
            goods: [],
            phone: null,
            area: null,
            street: null,
            house: null,
            entrance: null,
            floor: null,
            room: null,
            comment: null,
            deliveryMethod: 'delivery',
            payment: null,
            point: null,
            zones: null,
            goodPrice: 0,
            salePrice: 0,
            sale: null,
            totalPrice: 0,
            unit: null,
            changeCash: null,
            source: 'crm',
            isAggPrice: false,
            deliveryPrice: 0,
            deliveryArticle: null
        };
    }

    default: return state;
  }
}

