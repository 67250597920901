import axios from 'axios';
import { getCookie, deleteCookie } from '../utils/cookie';
import { baseUrl } from '../constants/app';

const request = function(options) {
    const client = axios.create({
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${getCookie('auth')}`
        }
    });

    const onSuccess = function(response) {
        return response.data;
    }

    const onError = function(error) {
        if (error.response && error.response.status === 401) {
            deleteCookie('auth');
            window.history.back();
        }
        return Promise.reject(error.response || error.message);
    }

    return client(options).then(onSuccess).catch(onError);
}

export default request;