import { urls } from '../constants/app';
import request from './utils';
import { setError, resetError } from './errors';
import moment from 'moment';

export function fetchSettings() {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'settings', value: true });

        return request({ method: 'get', url: `${urls.settings}` })
            .then((response) => {
                dispatch({ type: 'SET_SETTINGS', data: response.length ? response[0] : {}  });
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'settings'));
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return null
            });
        
    }
}

export function updateSettings(data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'settings', value: true });
        dispatch(resetError('settings'));
        return request({ method: 'put', url: `${urls.settings}/1`, data })
            .then((response) => {
                dispatch({ type: 'SET_SETTINGS', data: response ? response : {}  });
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'settings'));
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return null
            });
        
    }
}

export function setEditedSettings(field, value) {
    return dispatch => {
        dispatch({ type: 'SET_EDITED_SETTINGS', field, value });
    }
}

export function setPromocodePaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_PROMOCODES_PAGING', paging });
    }
}

export function fetchPromocodes() {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });

    const paging = getState().promocodes.paging;
    const sorting = getState().promocodes.sorting;
    const filters = getState().promocodes.filters;

    const params = {};
    const dataParams = {};

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;

    params['_sort'] = `${sorting.field}:${sorting.type}`;

    if (filters.city) {
        dataParams['city.id'] = filters.city.value;
        params['city.id'] = filters.city.value;
    }

    if (filters.startDate) {
        dataParams['startDate_gte'] = moment(filters.startDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['startDate_gte'] = moment(filters.startDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    if (filters.endDate) {
        dataParams['endDate_lte'] = moment(filters.endDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['endDate_lte'] = moment(filters.endDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    request({ method: 'get', url:  urls.promocodes+'/count', params: dataParams })
        .then(result => {
            dispatch({ type: 'SET_PROMOCODES_COUNT', count: result });
            return;
        }).then(() => {
            return request({ method: 'get', url: urls.promocodes, params }).then((response) => {
                const promocodes = response.map(item => {
                    return ({
                        ...item,
                        city: item.city ? { value: item.city.id, label: item.city.name } : null
                    });
                })
                dispatch({ type: 'SET_PROMOCODES', promocodes });
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
            });
        })
        .catch(error => {
            dispatch(setError(error, 'promocodes'));
            dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
        });
    }
}

export function setEditedPromocode(promocode) {
    return { type: 'SET_EDITED_PROMOCODE', promocode }
}

export function setDetailPromocode(promocode) {
    return { type: 'SET_PROMOCODE', promocode }
}

export function setPromocodeFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_PROMOCODES_FILTERS', filters });
    }
}

export function setDeletePromocode(promocode) {
    return { type: 'SET_DELETE_PROMOCODE', promocode }
}


export function updatePromocode(id, data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));

        const updatedData = { ...data };
        const cities = data.cities.map(item => item.value || item.id);
        updatedData.cities = cities;
        updatedData.city = cities[0] || null;

        return request({ method: 'put', url: `${urls.promocodes}/${id}`, data: updatedData })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}

export function setAddPromocode(promocode) {
    return { type: 'SET_ADD_PROMOCODE', promocode }
}

export function addPromocode(data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));
        const updatedData = { ...data };
        const cities = data.cities.map(item => item.value || item.id);
        updatedData.cities = cities;
        updatedData.city = cities[0] || null;

        return request({ method: 'post', url: `${urls.promocodes}`, data: updatedData })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 506 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}

export function deletePromocode(id) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));
        return request({ method: 'delete', url: `${urls.promocodes}/${id}` })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 507 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}
