import { createAction } from 'redux-actions';
import { urls } from '../constants/app';
import { makeActionType } from '../utils';
import { resetError, setError } from './errors';
import request from './utils';

const namespace = 'BLOCKED_IP';

export const setIps = createAction(makeActionType(namespace, ['SET']));
export const setCountIps = createAction(makeActionType(namespace, ['SET', 'COUNT']));
export const setIp = createAction(makeActionType(namespace, ['SET', 'IP']));
export const deleteIpAction = createAction(makeActionType(namespace, ['DELETE', 'IP']));
export const setPagingIps = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingIps = createAction(makeActionType(namespace, ['SET', 'SORTING']));

export function fetchIps(withoutLoader = false) {
    return async (dispatch, getState) => {
        !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'ips', value: true });
        dispatch(resetError('ips'));
        const paging = getState().ips.paging;

        const params = {
            _sort: 'created_at:DESC',
        };

        params['_start'] = paging.start;
        params['_limit'] = paging.limit;

        try {
            const ipsCount = await request({ method: 'get', url: urls.ips+'/count', params: { _sort: params._sort } });
            const ips = await request({ method: 'get', url: urls.ips, params });
            dispatch(setIps(ips))
            dispatch(setCountIps(ipsCount));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'ips', value: false });
        } catch(error) {
            dispatch(setError(error, 'ips'));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'ips', value: false });
        }
    }
}

export function updateIps(id, data) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'updateIps', value: id });
        dispatch(resetError('ips'));
        try {
            const ip = await request({ method: 'put', url: `${urls.ips}/${id}`, data });
            dispatch(setIp(ip));
            dispatch({ type: 'SET_LOADER', field: 'updateIps', value: null });
            return ip.id;
        } catch(error) {
            dispatch(setError(error, 'ips'));
            dispatch({ type: 'SET_LOADER', field: 'updateIps', value: null });
            return null;
        }
    }
}

export function deleteIps(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteIps', value: id });
        dispatch(resetError('ips'));
        try {
            await request({ method: 'delete', url: `${urls.ips}/${id}` });
            dispatch(deleteIpAction(id));
            dispatch({ type: 'SET_LOADER', field: 'deleteIps', value: null });
        } catch(error) {
            dispatch(setError({ status: 507 }, 'ips'));
            dispatch({ type: 'SET_LOADER', field: 'deleteIps', value: null });
        }
    }
}
