import { urls } from '../constants/app';
import { resetError, setError } from './errors';
import request from './utils';

export function fetchYumaposGoodMapsByGoodId(id) {
  return async (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'fetchYumaposGoodMapsByGoodId', value: true });
    dispatch(resetError('yumaposgoodmap'));
    try {
      const result = await request({ method: 'get', url: `${urls.yumaposgoodsmap}/getYumaposGoodMapsByGoodId/${id}` });
      dispatch({ type: 'SET_YUMAPOSGOODMAP_LIST', name: 'list', result });
      dispatch({ type: 'SET_LOADER', field: 'fetchYumaposGoodMapsByGoodId', value: false });
      return result;
    } catch (error) {
      dispatch(setError(error, 'yumaposgoodmap'));
      dispatch({ type: 'SET_LOADER', field: 'fetchYumaposGoodMapsByGoodId', value: false });
      return null;
    }
  }
}

export function updateYumaposGoodMaps(idGood) {
  return async (dispatch, getState) => {
    const updateList = getState().yumaposgoodsmap.updateList;
    const data = { id: idGood, updatedYumaposGoodMaps: updateList };
    dispatch({ type: 'SET_LOADER', field: 'updateYumaposGoodMaps', value: true });
    dispatch(resetError('updateYumaposGoodMaps'));
    try {
      const result = await request({ method: 'post', url: `${urls.yumaposgoodsmap}/updateYumaposGoodMaps`, data });
      if (result) {
        dispatch({ type: 'SET_LOADER', field: 'updateYumaposGoodMaps', value: false });
        dispatch({ type: 'SET_YUMAPOSGOODMAP_LIST', name: 'list', result });
        dispatch({ type: 'SET_YUMAPOSGOODMAP_UPDATELIST', name: 'updateList', result: [] });
      }
      return result;
    } catch (error) {
      dispatch(setError(error, 'updateYumaposGoodMaps'));
      dispatch({ type: 'SET_LOADER', field: 'updateYumaposGoodMaps', value: false });
      return null;
    }
  }
}

export const addYumaposGoodMapUpdateList = ({ id, goodId, goodIdYumapos, modification }) => {
  return {
    type: 'ADD_YUMAPOSGOODMAP_UPDATELIST',
    payload: { id, goodId, goodIdYumapos, modification },
  };
};