import { setFiltersGoods } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = {
  title: null,
  category: null,
};

export default createReducer(
  [forAction(setFiltersGoods, identityPayload)],
  initialState,
);
