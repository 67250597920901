import React from 'react';
import Content from '../components/layout/Content';
import ErrorContainer from '../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../selectors';
import { useDispatch } from 'react-redux';
import { fetchSliders, setActiveModalAction } from '../action';
import { SlidersList } from '../containers';

function SlidersPage() {
    const dispatch = useDispatch();
    const count = useSelector(fromStore.countSlidersSelector);

    const onRefresh = () => {
        dispatch(fetchSliders());
    };

    const onOpenModalGood = (id) => (e) => {
        e.stopPropagation();
        const sliderId = id || 'new';
        dispatch(setActiveModalAction({ field: 'slider', value: sliderId }));
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Слайдеры</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {count}
                        </span>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    <button onClick={onOpenModalGood()} className="btn btn-success btn-icon-sm">Добавить слайдер</button>
                    <button onClick={onRefresh} className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                </div>
            </div>
            </div>
        );
    };

    return (
        <Content>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <ErrorContainer field="sliders" hasCloseButton={true}/>
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-todo">
                    <SlidersList />
                </div>
            </div>
        </Content>
    );
}

export default SlidersPage;