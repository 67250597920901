import React, { Component } from 'react';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { getCookie } from './utils/cookie';
import { pages } from './constants/page';

function PrivateRoute({ component: Component, access, ...rest }) {
  return (
    <Route {...rest} render={props =>
        getCookie('auth')
          ?  (
          <Component {...props} />
          ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

class Main extends Component {
  render() {
    return (
      <Router>
        <Switch>
          { 
            pages.map(page => (
              <PrivateRoute key={page.name} access={page.access} exact={page.path === '/' || page.exact} path={page.path} component={MainPage} />
            ))
          }
          <Route path="/login" component={LoginPage} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

export default Main;
