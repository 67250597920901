import React, { useState, useEffect } from 'react';
import Loader from '../components/utils/Loader';
import Portlet from '../components/utils/Portlet';
import ReactPaginate from 'react-paginate';
import Input from '../components/utils/Input'
import ErrorContainer from '../components/utils/ErrorContainer';
import Content from '../components/layout/Content';
import DateInput from '../components/utils/DateInput';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchNews, setFiltersNews, setPagingNews } from '../action';
import { useSelector } from 'react-redux';
import { fromStore } from '../selectors';
import NewsList from '../containers/NewsList';
import { Link } from 'react-router-dom';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

function NewsPage() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState();
    const paging = useSelector(fromStore.pagingNewsSelector);
    const count = useSelector(fromStore.countNewsSelector);
    const filters = useSelector(fromStore.filtersNewsSelector);
    const items = useSelector(fromStore.newsListSelector);
    const loader = useSelector(fromStore.loaderNewsSelector);

    useEffect(() => {
        dispatch(fetchNews());
    }, [dispatch, paging]);

    const setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        setCurrentPage(0);
        dispatch(setPagingNews(updatedPaging));
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        let nextStart =  data.selected * paging.limit;
        const updatedPaging = {...paging, start: nextStart};
        dispatch(setPagingNews(updatedPaging));
    };

    const renderPagination = () => {
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={currentPage}
                    onPageChange={handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />
                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    const resetFilter = () => {
        dispatch(setFiltersNews({ city: null, point: null, status: null }))
        setCurrentPage(0);
        dispatch(fetchNews());
    }

    const onChangeDateInput = (field) => (date) => {
        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }
        dispatch(setFiltersNews(updated))
    }

    const onChangeInput = (field) => (e) => {
        const value = e.target.value;

        const updated = {
            ...filters,
            [field]: value
        }
        dispatch(setFiltersNews(updated))
    }

    const onSearch = () => {
        dispatch(setPagingNews({ ...paging, start: 0 }));
        setCurrentPage(0);
        dispatch(fetchNews());
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearch(); 
        }
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main" onKeyDown={onKeyDown}>
                    <h3 className="kt-subheader__title">Новости</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {count}
                        </span>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <div className="dateBlock">
                            <DateInput
                                placeholderText="Дата от"
                                selected={filters.createdStartDate}
                                onChange={onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                placeholderText="Дата до"
                                selected={filters.createdEndDate}
                                onChange={onChangeDateInput('createdEndDate')}
                                maxDate={moment(filters.createdStartDate).add({ days: 30 }).toDate()}
                                required={filters.createdStartDate}
                            />
                        </div>
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <Input placeholder="Заголовок" onChange={onChangeInput('title')} disableGroup={true} value={filters.title}/>
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <button style={{ marginRight: 5 }} onClick={onSearch} className="btn btn-primary">Найти</button>
                        <button onClick={resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    <Link className="btn btn-label-brand btn-bold" to="/addNews">Добавить новость</Link>
                </div>
            </div>
            </div>
        );
    }

    if (loader) {
        return (
            <div style={styles.loading}><Loader /></div>
        );
    }

    return (
        <Content>
            { renderSubHeader() }
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Portlet fit={true}>
                    <ErrorContainer field="news" style={{ margin: 15 }} hasCloseButton={true}/>
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                        <NewsList items={items}/>
                        {
                            items.length > 0 && renderPagination()
                        }
                    </div>
                </Portlet>
            </div>
        </Content>
    );
}

export default NewsPage;