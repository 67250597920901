import React, { useState, useEffect } from 'react';
import { withRouter  } from 'react-router-dom';
import Input from '../components/utils/Input';
import cn from 'classnames';
import ErrorContainer from '../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../selectors';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch } from 'react-redux';
import { fetchNewsDetail, updateNews } from '../action';
import Loader from '../components/utils/Loader';
import { getImageUrl } from '../utils/common';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const mdParser = new MarkdownIt();

function EditNews({ history, match }) {
    const dispatch = useDispatch();
    const id = match.params.id;
    const loadingSave = useSelector(fromStore.loaderAddNewsSelector);
    const loader = useSelector(fromStore.loaderNewsSelector);
    const detail = useSelector(fromStore.newsDetailSelector);

    const [addData, setAddData] = useState({});


    useEffect(() => {
        dispatch(fetchNewsDetail(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (detail) {
            setAddData({
                title: detail.title,
                description: detail.description,
                text: detail.text,
            });
        }
    }, [detail]);

    if (loader || !detail) {
        return (
            <div style={styles.loading}><Loader /></div>
        );
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">{detail.title}</h3>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => history.push('/news')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    const isValidNews = () => {
        const { title, description, text, image} = addData;
        if (title || description || text || image) return true;
        
        return false;
    }

    const setData = (field) => (e) => {
        const data = {...addData};
        data[field] = e.target.value;
        setAddData(data);
    }

    const setFile = (e) => {
       const data = {...addData};
       data.image = e.target.files[0];
       data.oldImageId = e.target.files[0];
       setAddData(data);
    }

    const handleEditorChange = ({ html, text }) => {
        const data = {...addData};
        data.text = text;
        setAddData(data);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const rq = { ...addData };
        if (rq.image) {
            rq.imageData = detail.image;
        }
        const result = await dispatch(updateNews(id, rq));
        if (result) {
            history.push('/news');
        }
    }


    return (
        <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor'>
            { renderSubHeader() }
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                
            <div className="kt-portlet">
                <form onSubmit={onSubmit} className="kt-form">
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Заголовок"
                                    required={true}
                                    onChange={setData('title')}
                                    value={addData.title}
                                    placeholder="Заголовок..."
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '25px' }}>
                            <div className="col-md-12">
                                <label>Картинка *</label>
                                {
                                    detail && detail.image && (
                                        <div style={{ marginBottom: '10px' }}>
                                            <img width="200px" src={getImageUrl(detail.image)} alt={detail.image.name}/>
                                        </div>
                                    )
                                }
                               
                                <div>
                                    <input type="file" onChange={setFile}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Краткое описание"
                                    required={true}
                                    onChange={setData('description')}
                                    value={addData.description}
                                    placeholder="Краткое описание..."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Полное описание *</label>
                                <MdEditor value={addData.text} style={{ height: '500px' }} renderHTML={text => mdParser.render(text)} onChange={handleEditorChange} />
                            </div>
                        </div>
                    </div>
                    <ErrorContainer field="addNews" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <button
                                disabled={!isValidNews() || loadingSave}
                                type="submit"
                                className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loadingSave })}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default withRouter(EditNews);