import React from 'react';
import moment from 'moment';

export const ItemRow = ({ item }) => {
    return (
        <tr key={`${item.id}_code`} className="kt-datatable__row">
            <td width="200px" className="kt-datatable__cell"><span>{item.phone}</span></td>
            <td width="100px" className="kt-datatable__cell"><span>{item.code}</span></td>
            <td width="200px" className="kt-datatable__cell">
                {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
            </td>
            <td width="100px" className="kt-datatable__cell"><span>{item.ip}</span></td>
            <td align='right' width="180px" className="kt-datatable__cell" />
        </tr>
    );
}
