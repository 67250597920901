import { loadersSelector, newsSelector } from './root';
import { propNameSelector } from '../utils';

export const newsListSelector = propNameSelector(newsSelector, 'list');
export const newsDetailSelector = propNameSelector(newsSelector, 'detail');
export const pagingNewsSelector = propNameSelector(newsSelector, 'paging');
export const sortingNewsSelector = propNameSelector(newsSelector, 'sorting');
export const filtersNewsSelector = propNameSelector(newsSelector, 'filters');
export const countNewsSelector = propNameSelector(newsSelector, 'count');
export const loaderNewsSelector = propNameSelector(loadersSelector, 'news');
export const loaderAddNewsSelector = propNameSelector(loadersSelector, 'newsAdd');