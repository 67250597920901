import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { fetchChanels, setNewMessageForm, addMessage, resetNewMessageForm, fetchMessages } from '../../action/messages';
import cn from 'classnames';
import swal from 'sweetalert';
import ErrorContainer from '../../components/utils/ErrorContainer';

export class NewMessage extends Component {
    componentDidMount() {
        !this.props.user && this.props.fetchChanels();
    }

    componentWillUnmount() {
        this.props.resetNewMessageForm();
    }
    

    onChangeNewMessage = (field) => (value) => {
        this.props.setNewMessageForm(field, value);
    }

    isValidMessage() {
        const { newMessage } = this.props;
        const { chanels, theme, message } = newMessage;
        if (!this.props.user) {
            if (!chanels.length || !theme || !message) {
                return false;
            }
        } else {
            if (!theme || !message) {
                return false;
            }
        }
        return true;
    }

    addMessage = () => {
        const { newMessage, chanels: chanelsData, user } = this.props;
        const { chanels, theme, message } = newMessage;
        let updatedChanels = null;
        if (user) {
            updatedChanels = [{
                title: 'Пользователь',
                key: 'user',
                value: {
                    ...user.deviceToken,
                    phone: user.phone
                }
            }];
        } else {
            updatedChanels = chanels.map(item => {
                return chanelsData.find(o => o.id === item.value);
            });
        }
        
        const data = {
            theme,
            message,
            chanels: updatedChanels
        };
        this.props.addMessage(data).then(result => {
            if (result === 'success') {
                swal({
                    title: "Успех",
                    text: "Сообщение отправлено",
                    icon: "success",
                    confirmButtonText: 'OK'
                });
                this.props.fetchMessages();
                this.props.onClose();
            }
        });
    }

    render() {
        return (
            <div className="modal modal-sticky-bottom-right modal-sticky-lg show" style={{display: 'block'}}>
                <div className="modal-dialog">
                    <div className="modal-content kt-inbox">
                        <div className="kt-inbox__form">
                            <div className="kt-inbox__head">
                                <div className="kt-inbox__title">Новое уведомление</div>
                                <div className="kt-inbox__actions">
                                    <button onClick={this.props.onClose} className="kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light">
                                        <i className="flaticon2-cross"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="kt-inbox__body">
                                <div className="kt-inbox__to">
                                    <div className="kt-inbox__wrapper">
                                        <div className="kt-inbox__field kt-inbox__field--to">
                                            <div style={{ marginRight: 10, width: this.props.user ? 85 : 40 }} className="kt-inbox__label">
                                                {this.props.user ? 'Пользователь' : 'Канал'}
                                            </div>
                                            <div className="kt-inbox__input">
                                                {
                                                    this.props.user ? (
                                                    <div>{this.props.user.phone}</div>
                                                    ) : (
                                                        <Select
                                                            isLoading={this.props.loadingChanel}
                                                            isDisabled={this.props.loadingChanel || this.props.addLoader}
                                                            value={this.props.newMessage.chanels}
                                                            onChange={(item) => this.onChangeNewMessage('chanels')([item] || [])}
                                                            closeMenuOnSelect
                                                            options={this.props.chanels.map(item => ({
                                                                value: item.id,
                                                                label: item.title
                                                            }))}
                                                            placeholder="Выберите канал"
                                                            noOptionsMessage={() => 'Нет каналов для выбора'}
                                                        />
                                                    )
                                                }
                                               
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="kt-inbox__subject">
                                    <input disabled={this.props.addLoader} value={this.props.newMessage.theme} onChange={(e) => this.onChangeNewMessage('theme')(e.target.value)} className="form-control" placeholder="Заголовок уведомления"/>
                                </div>

                                <div className="kt-inbox__editor ql-container ql-snow" id="kt_inbox_compose_editor" style={{ height: 300 }}>
                                   <textarea disabled={this.props.addLoader} value={this.props.newMessage.message} onChange={(e) => this.onChangeNewMessage('message')(e.target.value)} className="customTextArea" placeholder="Текст сообщения"></textarea>
                                </div>
                            </div>
                            <div className="kt-inbox__foot">
                                <ErrorContainer field="newMessage" style={{ margin: 15 }} hasCloseButton={true}/>
                                <div className="kt-inbox__primary">
                                    <div className="btn-group">
                                        <button
                                            onClick={this.addMessage}
                                            disabled={!this.isValidMessage() || this.props.addLoader}
                                            type="button"
                                            className={cn({ 'btn btn-brand btn-bold': true })}>
                                            Отправить
                                        </button>                                   
                                       
                                        
                                    </div>
                                        {
                                            this.props.addLoader && (
                                                <div style={{ marginLeft: 10 }} className="kt-spinner kt-spinner--md kt-spinner--info"></div>
                                            )
                                        }

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loadingChanel: state.loaders.chanels,
    chanels: state.messages.chanels,
    newMessage: state.messages.newMessage,
    addLoader: state.loaders.message
});

const mapDispatchToProps = {
    fetchChanels: () => fetchChanels(),
    setNewMessageForm: (field, value) => setNewMessageForm(field, value),
    addMessage: (data) => addMessage(data),
    resetNewMessageForm: () => resetNewMessageForm(),
    fetchMessages: () => fetchMessages('sended', { status: 'sended' }),
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage)
