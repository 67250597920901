import { deleteKassaAction, addKassaList, setKassaAccount, setKassaAccounts } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [
    forAction(setKassaAccounts, identityPayload),
    forAction(setKassaAccount, (state, { payload: good }) => {
      const index = state.findIndex((item) => item.id === good.id);
      const updated = [...state];
      updated[index] = { ...good };
      return updated;
    }),
    forAction(addKassaList, (state, { payload: good }) => {
      const updated = [...state, good];
      return updated;
    }),
    forAction(deleteKassaAction, (state, { payload: id }) => {
      return state.filter((item) => item.id !== id);
    })
  ],
  initialState,
);
