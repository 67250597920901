import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setAddCity, addFilial, fetchCities } from '../../action/handbook';
import cn from 'classnames';
import Loader from '../utils/Loader';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl } from 'react-yandex-maps';
import { createSelector } from 'reselect'
import Select from 'react-select';
import { fetchGoods, fetchCategories } from '../../action/goods';
import { fetchKassaAccounts } from '../../action/yookassa';
import Switch from '../utils/Switch';
import { optionsMinDeliveryTime, optionsMinSamTime } from '../../constants/app'

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const activateSelect = {
    'active': 'Активирован',
    'disabledAll': 'Полностью остановлен',
    'disabledDelivery': 'Доставка остановлена',
    'disabledOwn': 'Самовывоз остановлен',
};

class AddFilial extends Component {

    componentDidMount() {
        this.props.fetchCities();
        this.props.fetchKassaAccounts();
        const cityId = this.props.location.state && this.props.location.state.city;
        this.props.setAddCity({ city: cityId, activate: 'active' });
        this.props.fetchGoods();
        this.props.fetchCategories();
    }

    componentWillUnmount() {
        this.props.setAddCity({});
    }
    
    renderSubHeader() {
        const { state } = this.props.location;
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Добавить филиал</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push(state && state.city && state.from === 'edit' ? `/handbook/cities/edit/${state.city}` : '/handbook/filials' )} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidFilial() {
        const { city, street, house, email, phone, timeWork, coord, minDeliveryTime, minSamTime } = this.props.addForm;
        if (city && street && house && email && phone && timeWork && coord && minDeliveryTime && minSamTime) return true;
        return false;
    }

    setCity = (field) => (e) => {
        const data = {...this.props.addForm};
        
        if (field === 'minDeliveryTime' || field === 'minSamTime') {
            data[field] = e.value;
        }

        if (field === 'onlinePay' || field === 'stopList' || field === 'stop' || field === 'directOrder' || field === 'disableLoyalSystem' || field === 'onlineDirectOrder') {
            data[field] = e;
        } else if (field !== 'minDeliveryTime' && field !== 'minSamTime') {
            data[field] = e.target.value;
        }
        this.props.setAddCity(data);
    }

    setFields = (fields) => {
        const data = {...this.props.addForm};

        for (let field in fields) {
            data[field] = fields[field];
        }

        this.props.setAddCity(data);
    }

    setOnlinePay = (value) => {
        if(value) {
            this.setCity('onlinePay')(value);
        } else {
            const fields = {
                onlinePay: value,
                onlineDirectOrder: value
            }
            this.setFields(fields);
        }
    }

    setDirectOrder = (value) => {
        if(value && this.props.addForm.disableLoyalSystem) {
            const fields = {
                directOrder: value,
                disableLoyalSystem: !value
            }
            this.setFields(fields)
        } else {
            this.setCity('directOrder')(value)
        }
    }

    setDisableLoyalSystem = (value) => {
        if(value && this.props.addForm.directOrder) {
            const fields = {
                directOrder: !value,
                disableLoyalSystem: value
            }
            this.setFields(fields)
        } else {
            
            this.setCity('disableLoyalSystem')(value)
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { state } = this.props.location;
        this.props.addFilial(this.props.addForm).then(result => {
            if (result) {
                state && state.city && state.backTo ? this.props.history.push(state.backTo) : state && state.city && state.from === 'edit'
                    ? this.props.history.push(`/handbook/cities/edit/${state.city}`)
                    : this.props.history.push('/handbook/filials')
            }
        });
    }

    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    setCoord = (value) => {
        const data = {...this.props.addForm};
        data['coord'] = value;
        this.props.setAddCity(data);
    }

    renderOnlinePayment = () => {
        const { addForm, kassaAccounts } = this.props;
    
        return (
            <div className='online-pay_block'>
                 <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="col-10 col-form-label">Онлайн-оплата</label>
                                <div className="col-2">
                                    <Switch 
                                        onChange={(value) => this.setOnlinePay(value)} 
                                        checked={addForm.onlinePay} 
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="col-10 col-form-label">Прямой заказ онлайн</label>
                                <div className="col-2">
                                    <Switch 
                                        onChange={this.setCity('onlineDirectOrder')} 
                                        checked={addForm.onlineDirectOrder}
                                        disabled={!addForm.onlinePay}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Input
                            label="ФИО ИП"
                            onChange={this.setCity('legalName')}
                            value={addForm.legalName}
                            placeholder="ФИО ИП..."
                        />
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="ИНН"
                            onChange={this.setCity('inn')}
                            value={addForm.inn}
                            placeholder="ИНН..."
                        />
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="ОГРНИП"
                            onChange={this.setCity('ogrnip')}
                            value={addForm.ogrnip}
                            placeholder="ОГРНИП..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Input
                            label="Почтовый адрес"
                            onChange={this.setCity('legalAdress')}
                            value={addForm.legalAdress}
                            placeholder="Почтовый адрес..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Аккаунт Юкассы</label>
                            <select value={addForm && addForm.yookassa} onChange={this.setCity('yookassa')} className="form-control">
                                <option value="">Выберите аккаунт</option>
                                {
                                    kassaAccounts.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name} - {item.secret}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="SHOP_ID"
                            onChange={this.setCity('shopId')}
                            value={addForm.shopId}
                            placeholder="SHOP_ID..."
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.citiesLoader) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        const cityId = this.props.location.state && this.props.location.state.city;
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    
                <div className="kt-portlet">
                    <form onSubmit={this.onSubmit} className="kt-form">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Город *</label>
                                        <select disabled={cityId} value={cityId ? cityId : this.props.addForm.city} onChange={this.setCity('city')} className="form-control">
                                            <option value="">Выберите город</option>
                                            {
                                                this.props.cities.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Frontpad ID"
                                        onChange={this.setCity('frontpadId')}
                                        value={this.props.addForm.frontpadId}
                                        placeholder="ID..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Время работы"
                                        required={true}
                                        onChange={this.setCity('timeWork')}
                                        value={this.props.addForm.timeWork}
                                        placeholder="Время работы..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        label="Улица"
                                        required={true}
                                        onChange={this.setCity('street')}
                                        value={this.props.addForm.street}
                                        placeholder="Улица..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Дом"
                                        required={true}
                                        onChange={this.setCity('house')}
                                        value={this.props.addForm.house}
                                        placeholder="Дом..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Подъезд"
                                        onChange={this.setCity('entrance')}
                                        value={this.props.addForm.entrance}
                                        placeholder="Подъезд..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        label="Квартира / офис"
                                        onChange={this.setCity('room')}
                                        value={this.props.addForm.room}
                                        placeholder="Квартира..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Email"
                                        required={true}
                                        onChange={this.setCity('email')}
                                        value={this.props.addForm.email}
                                        placeholder="Телефон..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Телефон"
                                        required={true}
                                        onChange={this.setCity('phone')}
                                        value={this.props.addForm.phone}
                                        placeholder="Телефон..."
                                    />
                                </div>
                            </div>
                            {this.renderOnlinePayment()}
                            <div className="row">
                                <div className="col-md-4">
                                    <label>{"Минимальное время доставки *"}</label>
                                    <Select
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinDeliveryTime}
                                        onChange={this.setCity('minDeliveryTime')}
                                        value={{value: this.props.addForm.minDeliveryTime, label: this.props.addForm.minDeliveryTime}}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label>{"Минимальное время самовывоза *"}</label>
                                    <Select
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinSamTime}
                                        onChange={this.setCity('minSamTime')}
                                        value={{value: this.props.addForm.minSamTime, label: this.props.addForm.minSamTime}}
                                    />
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label>Стоп лист товаров</label>
                                    <Select
                                        isLoading={this.props.loadingGoods}
                                        isDisabled={this.props.loadingGoods}
                                        menuPortalTarget={document.body}
                                        isMulti
                                        isSearchable
                                        closeMenuOnSelect={true}
                                        options={this.props.goods}
                                        placeholder="Товары"
                                        noOptionsMessage={() => 'Нет товаров'}
                                        onChange={this.setCity('stopList')}
                                        value={this.props.addForm.stopList}
                                    />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Доступность</label>
                                        <div className="col-12">
                                            <select onChange={(e) => this.setCity('activate')(e)} value={this.props.addForm.activate} className="form-control">
                                                {
                                                    Object.keys(activateSelect).map((key, index) => (
                                                        <option key={key} value={key}>{activateSelect[key]}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Прямой заказ</label>
                                        <div className="col-12">
                                            <Switch onChange={(value) => this.setDirectOrder(value)} checked={this.props.addForm.directOrder} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Игнорировать систему лояльности</label>
                                        <div className="col-12">
                                            <Switch onChange={(value) => this.setDisableLoyalSystem(value)} checked={this.props.addForm.disableLoyalSystem} />
                                        </div>
                                    </div>
								</div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        label="Координаты"
                                        required={true}
                                        onChange={this.setCity('coord')}
                                        value={this.props.addForm.coord}
                                        placeholder="Координаты..."
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <YMaps
                                        style={{ padding: '25px ', marginTop: -30 }}
                                        query={{
                                            apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                                        }}
                                        >
                                        <Map
                                            
                                            width="100%"
                                            defaultState={{ center: [47.222127, 39.720367], zoom: 15, controls: [] }}
                                        >
                                        <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                        </div>
                        <ErrorContainer field="addFilial" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidFilial() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.loading })}>
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const goodsSelector = state => state.good.list;
    const categoriesSelector = state => state.good.categories;

    const normalizeGoodsSelector = createSelector(
        goodsSelector,
        categoriesSelector,
        (goods, categories) => {
            const resultCategories = categories.reduce((data, item) => {
                data[item.id] = item.title;
                return data;
            }, {});

            const resultGoods = goods.reduce((data, item) => {
                if (data[item.category.id]) {
                    data[item.category.id].push({ value: item.id, label: item.name });
                } else {
                    data[item.category.id] = [{ value: item.id, label: item.name }];
                }
                return data;
            }, {});
            
            const result = Object.keys(resultGoods).map(key => {
                return ({
                    label: resultCategories[key],
                    options: resultGoods[key],
                });
            }).filter(item => item.label);

            return result;
        }
    );

    return ({
        addForm: state.handbook.add,
        loading: state.loaders.addFilial,
        cities: state.handbook.cities,
        citiesLoader: state.loaders.cities,
        loadingGoods: state.loaders.goods || state.loaders.category,
        goods: normalizeGoodsSelector(state),
        kassaAccounts: state.yookassa.list,
    });
}

const mapDispatchToProps = {
    setAddCity: (data) => setAddCity(data),
    addFilial: (data) => addFilial(data),
    fetchCities: () => fetchCities(true),
    fetchGoods: () => fetchGoods(),
    fetchCategories: () => fetchCategories(),
    fetchKassaAccounts: () => fetchKassaAccounts(true),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFilial);