import { deleteGoodAction, addGoodList, setGood, setGoods } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [
    forAction(setGoods, identityPayload),
    forAction(setGood, (state, { payload: good }) => {
      const index = state.findIndex((item) => item.id === good.id);
      const updated = [...state];
      updated[index] = { ...good };
      return updated;
    }),
    forAction(addGoodList, (state, { payload: good }) => {
      const updated = [...state, good];
      return updated;
    }),
    forAction(deleteGoodAction, (state, { payload: id }) => {
      return state.filter((item) => item.id !== id);
    })
  ],
  initialState,
);
