import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { deleteIps, updateIps } from '../../../action';
import swal from 'sweetalert';

export const ItemRow = ({ item }) => {
    const dispatch = useDispatch();
    const loaderUpdateIp = useSelector(fromStore.loaderUpdateIpsSelector);
    const loaderDeleteIp = useSelector(fromStore.loaderDeleteIpsSelector);
    const loaderUpdate = loaderUpdateIp === item.id;
    const loadertDelete = loaderDeleteIp === item.id;

    const onToogleActiveIp = () => {
        const data = { enabled: !item.enabled };
        dispatch(updateIps(item.id, data))
    };

    const onDeleteIp = () => {
        swal({
            title: `Удаление IP`,
            text: `Вы действительно хотите удалить IP ${item.ip}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteIps(item.id));
            }
        });
    }

    return (
        <tr key={`${item.id}_ip`} className="kt-datatable__row">
            <td width="100px" className="kt-datatable__cell"><span>{item.ip}</span></td>
            <td width="200px" className="kt-datatable__cell">
                {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
            </td>
            <td width="300px" className="kt-datatable__cell"><span>{item.phone}</span></td>
            <td align='right' width="180px" className="kt-datatable__cell">
                <button
                    onClick={onToogleActiveIp}
                    style={{ marginRight: '3px' }}
                    data-tip={item.enabled ? 'Выключить' : 'Включить'}
                    disabled={loaderUpdate || loadertDelete}
                    className={cn("btn btn-sm btn-icon btn-icon-md", {
                        'btn-danger': !item.enabled,
                        'btn-success': item.enabled,
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                    })}>
                    {!loaderUpdate && <i className="la la-power-off"></i>}
                </button>
                <button
                    onClick={onDeleteIp}
                    disabled={loaderUpdate || loadertDelete}
                    data-tip="Удалить" 
                    className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': loadertDelete
                    })}>
                        {!loadertDelete && <i className="la la-trash"></i>}
                    </button>
            </td>
        </tr>
    );
}
