import { createAction } from 'redux-actions';
import { urls } from '../constants/app';
import { makeActionType } from '../utils';
import { resetError, setError } from './errors';
import request from './utils';

const namespace = 'PHONE_CODES';

export const setPhoneCodes = createAction(makeActionType(namespace, ['SET']));
export const setCountPhoneCodes  = createAction(makeActionType(namespace, ['SET', 'COUNT']));
export const setPagingPhoneCodes  = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingPhoneCodes = createAction(makeActionType(namespace, ['SET', 'SORTING']));

export function fetchPhoneCodes(withoutLoader = false) {
    return async (dispatch, getState) => {
        !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'phonecodes', value: true });
        dispatch(resetError('phonecodes'));

        const paging = getState().phonecodes.paging;

        const params = {
            _sort: 'created_at:DESC',
        };

        params['_start'] = paging.start;
        params['_limit'] = paging.limit;
    
        try {
            const codesCount = await request({ method: 'get', url: urls.phonecodes+'/count', params: { _sort: params._sort } });
            const codes = await request({ method: 'get', url: urls.phonecodes, params });
            dispatch(setPhoneCodes(codes))
            dispatch(setCountPhoneCodes(codesCount));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'phonecodes', value: false });
        } catch(error) {
            dispatch(setError(error, 'phonecodes'));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'phonecodes', value: false });
        }
    }
}
