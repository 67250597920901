import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th width="200px" className="kt-datatable__cell"><span>Телефон</span></th>
            <th width="100px" className="kt-datatable__cell"><span>Код</span></th>
            <th width="200px" className="kt-datatable__cell"><span>Дата отправки</span></th>
            <th width="100px" className="kt-datatable__cell"><span>IP</span></th>
            <th style={{ textAlign: 'right' }} width="180px" className="kt-datatable__cell"><span>Действия</span></th>
        </tr>
    </thead>
);