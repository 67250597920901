const initialState = {
    list: [],
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    filters: {
      kitchen: null,
      delivery: null,
      service: null,
      createdStartDate: null,
      createdEndDate: null
    },
    middle: {},
    count: 0
};

export default function ratings(state = initialState, action) {
  switch(action.type) {
    case 'SET_RATINGS': {
          return {
              ...state,
              list: action.ratings
          };
    }
    case 'SET_RATING_MIDDLE': {
      return {
          ...state,
          middle: action.middle
      };
    }
    case 'SET_RATINGS_FILTERS': {
      return {
          ...state,
          filters: action.filters
      };
    }
    case 'SET_RATINGS_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
}
    case 'SET_RATINGS_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    default: return state;
  }
}