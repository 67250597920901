import React from 'react';
import cn from 'classnames';

function Input({ id = '', disableGroup, label, required, onChange, value = "", type = "text", placeholder, error, disabled, description, textarea = false, rows, name, isControlledInput = false  }) {
    return (
        <div className={cn({ 'form-group': !disableGroup})}>
            {label && <label>{label}{required ? " *" : ""}</label>}
            {textarea ? (
                <textarea
                    id={id}
                    value={value || ''}
                    onChange={onChange}
                    className={cn({ 'form-control': true, 'is-invalid': error })}
                    autoComplete="false"
                    placeholder={placeholder}
                    disabled={disabled}
                    rows={rows}
                    name={name}
                />
            ) : ( isControlledInput ? (
                <input
                    id={id}
                    onChange={onChange}
                    value={value || value === 0 ? value : ''}
                    type={type}
                    className={cn({ 'form-control': true, 'is-invalid': error })}
                    autoComplete="false"
                    placeholder={placeholder}
                    disabled={disabled}
                    name={name}
                />
                ) : (
                <input
                    id={id}
                    onChange={onChange}
                    defaultValue={value || value === 0 ? value : ''}
                    type={type}
                    className={cn({ 'form-control': true, 'is-invalid': error })}
                    autoComplete="false"
                    placeholder={placeholder}
                    disabled={disabled}
                    name={name}
                />
            ))}
           
            {
                description && (
                    <span style={{ marginTop: 5 }} className="form-text text-muted">{description}</span>
                )
            }
            {
                error && (
                    <div className="error invalid-feedback">
                        {error}
                    </div>
                )
            }
            
        </div>
    );
}

export default Input;