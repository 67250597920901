import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addSlider, setActiveModalAction, updateSlider } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import { getImageUrl } from '../../utils/common';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Switch from '../../components/utils/Switch';

function SliderModal() {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).slider;
  const loading = useSelector(fromStore.loaderAddSliderSelector);
  const loadingUpdate = useSelector(fromStore.loaderUpdateSliderSelector);
  const slider = useSelector(fromStore.sliderById(id));

  const loader = loading || loadingUpdate;

  const initData = slider ? {
    title: slider.title,
    active: slider.active,
    description: slider.description,
    priority: slider.priority ? slider.priority : 0
  } : {
    title: '',
    description: '',
    active: true,
    priority: 0,
  };
  const [data, setData] = useState(initData);
  const isEditMode = id !== 'new';

  const onChangeInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.value;
    setData(updated);
  };

  const onChangeSwitch = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  };

  const onSetFile = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.files[0];
    setData(updated);
  };

  const isValid = () => {
    const { title, image } = data;
    if (isEditMode) {
      if (title || image) return true;
    }
    if (title && image) return true;

    return false;
  }

  const onClose = () => !loader && dispatch(setActiveModalAction({ field: 'slider', value: null }));
  useOnClickOutside(ref, onClose);

  const onSubmit = async (e) => {
    e.preventDefault();
    const rq = { ...data };
    if (!isEditMode) {
      const result = await dispatch(addSlider(rq));
      if (result) {
        onClose();
      }
    } else {
      if (rq.image) {
        rq.imageData = slider.image;
      }
      const result = await dispatch(updateSlider(id, rq));
      if (result) {
        onClose();
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование слайдера' : 'Добавление слайдера'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="g-name" className="form-control-label">Заголовок *</label>
                  <input disabled={loader} type="text" value={data.title} className="form-control" id="g-name" onChange={onChangeInput('title')} />
                </div>
               
                <div className="form-group">
                  <label htmlFor="g-image" className="form-control-label">Изображение *</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {slider && (
                      <img style={{ marginRight: '20px' }} width="100px" src={ getImageUrl(slider.image) } alt="" />
                    )}
                    <input disabled={loader} onChange={onSetFile('image')} type="file" style={{ padding: '6px' }} className="form-control" id="g-image" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-control-label">Описание</label>
                  <textarea minLength={3} maxLength={500} disabled={loader} value={data.description} className="form-control" onChange={onChangeInput('description')} />
                </div>

                <div className="form-group">
                  <label htmlFor="g-priority" className="form-control-label">Приоритет</label>
                  <input disabled={loader} type="number" value={data.priority} className="form-control" id="g-priority" onChange={onChangeInput('priority')} />
                </div>

                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Включен</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch disabled={loader} nomargin={true} onChange={onChangeSwitch('active')} checked={!!data.active}/>
                    </div>
                </div>
                <ErrorContainer field="addSlider" hasCloseButton={true}/>
            </div>

            <div className="modal-footer">
              <button disabled={loader} type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loader}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loader })}>
                  {isEditMode ? 'Сохранить' : 'Добавить товар'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SliderModal;