import React, { useState } from 'react';
import { TABS } from '../constants';
import cn from 'classnames';
import { AvailableGood } from './AvailableGood';
import { RegionPrices } from './RegionPrices';
import { ModificationsGood } from './ModificationsGood';
import { setActiveModalAction } from '../../../action';
import { useDispatch } from 'react-redux';

export function Tabs() {
    const dispatch = useDispatch();
    const [activeTab, setTab] = useState(TABS[0].key);

    const setActiveTab = (key) => () => {
        if (key !== activeTab) setTab(key);
    };

    const onOpenModalStopList = (e) => {
        e.stopPropagation();
        dispatch(setActiveModalAction({ field: 'stopList', value: true }));
    };

    const onOpenModalRegionPrice = (e) => {
        e.stopPropagation();
        dispatch(setActiveModalAction({ field: 'regionPrice', value: 'new' }));
    };

    const onOpenModalModification = (e) => {
        e.stopPropagation();
        dispatch(setActiveModalAction({ field: 'modification', value: 'new' }));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'available': return <AvailableGood />;
            case 'regionPrices': return <RegionPrices />;
            case 'modifications': return <ModificationsGood />;
            default: return null;
        }
    }

    const renderButtons = () => {
        if (activeTab === 'available') {
            return (
                <button onClick={onOpenModalStopList} className="btn btn-label-brand btn-sm btn-bold">Редактировать доступность</button>
            );
        }
        if (activeTab === 'regionPrices') {
            return (
                <button onClick={onOpenModalRegionPrice} className="btn btn-label-brand btn-sm btn-bold">Добавить цену</button>
            );
        }

        return <button onClick={onOpenModalModification} className="btn btn-label-brand btn-sm btn-bold">Добавить модификацию</button>;
    }

    return (
        <div className="kt-portlet kt-portlet--tabs">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-toolbar">
                    <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand">
                        {TABS.map((item) => (
                            <li key={item.key} className="nav-item">
                                <button onClick={setActiveTab(item.key)} className={cn("nav-link", { active: item.key === activeTab })}> <i className={item.icon}></i> {item.title}</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="kt-portlet__body" style={{ minHeight: '350px', padding: 0 }}>
                <div className="tab-content">
                    {renderTabContent()}
                </div>
            </div>
            <div className="kt-portlet__foot">
                <div className="kt-form__actions kt-space-between">
                    {renderButtons()}
                </div>
            </div>
        </div>
    );
}