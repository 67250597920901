import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setAddPayment, addPayment } from '../../action/handbook';
import cn from 'classnames';
import ErrorContainer from '../utils/ErrorContainer';
import Switch from '../utils/Switch';

class AddPayment extends Component {
    componentWillUnmount() {
        this.props.setAddPayment({});
    }
    
    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Добавить способ оплаты</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push('/handbook/payments')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidPayment() {
        const { Name, VisibleName } = this.props.addForm;
        if (Name && VisibleName) return true;
        
        return false;
    }

    setVisibleClient = (value) => {
        this.setPayment('VisibleClient')(value);
    }

    setPayment = (field) => (e) => {
        const data = {...this.props.addForm};
        if(field !== 'VisibleClient')
            data[field] = e.target.value;
        if(field === 'VisibleClient')
            data[field] = e;
        this.props.setAddPayment(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.addPayment(this.props.addForm).then(result => {
            if (result) {
                this.props.history.push('/handbook/payments');
            }
        });
    }    

    render() {
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">                    
                <div className="kt-portlet">
                    <form onSubmit={this.onSubmit} className="kt-form">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Название"
                                        required={true}
                                        onChange={this.setPayment('VisibleName')}
                                        value={this.props.addForm.VisibleName}
                                        placeholder="Название..."
                                    />
                                    <Input
                                        label="Обозначение"
                                        required={true}
                                        onChange={this.setPayment('Name')}
                                        value={this.props.addForm.Name}
                                        placeholder="Обозначение..."
                                    />                                    
                                </div>
                                <div className="col-md-6">
                                    <label className="col-10 col-form-label">Виден клиентам</label>
                                    <div className="col-2">
                                        <Switch
                                            onChange={(value) => this.setVisibleClient(value)} 
                                            checked={this.props.addForm.VisibleClient} 
                                        />
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <ErrorContainer field="addPayment" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidPayment() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.loading })}>
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    addForm: state.handbook.add,
    loading: state.loaders.addPayment
});

const mapDispatchToProps = {
    setAddPayment: (data) => setAddPayment(data),
    addPayment: (data) => addPayment(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);