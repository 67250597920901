import React from "react";
import Select, { components } from "react-select";
import _ from 'lodash';

export const CHECKED = 1;
export const UNCHECKED = 2;
export const INDETERMINATE = -1;

const styles = {
  cursor: "pointer",
  display: "flex"
};

export const selectStyles = {
  container: () => ({
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "13px",
    flexDirection: "column",
    maxWidth: 300,
    height: 38.4, //
  }),
  control: (provided, state) => {
    const borderColor = state.isFocused ? "#53aad2" : "#dddee4";
    return {
      ...provided,
      "&:hover": "#dddee4",
      borderColor,
      width: "100%",
      boxShadow: null,
      height: 38.4,
    };
  },
  dropdownIndicator: (provided, state) => {
    const transform = state.selectProps.menuIsOpen ? "rotate(180deg)" : null;
    return {
      ...provided,
      transform,
      height: 38.4,
      color: state.isDisabled ? "#bdbdbd" : "#333333",
      opacity: state.isDisabled ? 1 : 0.7,
      ":hover": { opacity: 1 }
    };
  },
  indicatorSeparator: (s) => ({ ...s, width: 0 }),
  menu: (provided) => ({ 
    ...provided, 
    marginTop: 2, 
    position: "static",
}),
  menuList: (provided) => ({
    ...provided,
    minWidth: 300,
  }),
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      backgroundColor: null,
      color: isDisabled ? "#dddee4" : isSelected ? "#53aad2" : "#333333",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#dbf0ff" : "#1b76b9")
      },
      ":hover": {
        backgroundColor: !isDisabled && !isSelected && "#f1f9ff"
      },
      padding: "3px 0px"
    };
  },
  placeholder: (provided) => ({
    ...provided,
    fontSize: 13,
    color: "#bdbdbd"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#53aad2" : "#dddee4",
    fontWeight: 500,
    fontSize: 13
  })
};

const IndeterminateCheckbox = (props) => {
  const { value, ...otherProps } = props;
  const checkRef = React.useRef();

  React.useEffect(() => {
    checkRef.current.checked = value === CHECKED;
    checkRef.current.indeterminate = value === INDETERMINATE;
  }, [value]);

  return (
    <input
      type="checkbox"
      ref={checkRef}
      {...otherProps}
      onChange={props.onChange}
      style={{ cursor: "pointer" }}
    />
  );
};

const Option = (props) => {
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.selectOption(props.data, "set-value");
  };
  return (
    <div
      style={{ display: "flex", ...props.getStyles("option", props) }}
      onClick={onClick}
    >
      <IndeterminateCheckbox
        value={props.isSelected ? CHECKED : UNCHECKED}
        key={props.isSelected}
      />
       {props.label}
    </div>
  );
};

const Group = (props) => {
  const { data, options, selectProps } = props;
  const { handleToggleGroup, openGroups } = selectProps.innerProps;
  const isOpen = openGroups.includes(data.label);

  const selected = options.reduce(
    (prev, curr) => (prev += curr.isSelected ? 1 : 0),
    0
  );
  const value =
    selected === options.length
      ? CHECKED
      : selected === 0
      ? UNCHECKED
      : INDETERMINATE;

  const onToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleGroup(data.label, isOpen);
  };

  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const mapped = options.map(({ data }) => data);
    
    if (value === CHECKED || value === INDETERMINATE) {
      const newVal = selectProps.value.filter(item => {
        if(_.some(mapped, item)) {
          return false
        }
        return true
      })

      selectProps.onChange([...newVal], {action: "set-value"});
    }

    if (value === UNCHECKED) {
      if(selectProps.value === null || selectProps.value === undefined) {
        selectProps.onChange([...mapped], {action: "set-value"});
      } else {
        selectProps.onChange([...selectProps.value, ...mapped], {action: "set-value"});
      }
    }

  };

  return (
    <div onClick={onToggle}>
      <div style={styles}>
        <IndeterminateCheckbox value={value} onClick={onChange} key={value} />
        {data.label}
      </div>
      {isOpen && <div style={{ marginLeft: "15px" }}>{props.children}</div>}
    </div>
  );
};

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} ${length === 1 ? "пункт" : length < 5 ? "пункта" : "пунктов"}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 1;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

export default (props) => {
  const { isLoading, isDisabled, goods, stopList, id, setFilial } = props;
  const [selected, setSelected] = React.useState([]);
  const [openGroups, setOpenGroups] = React.useState([]);

  React.useEffect(() => {
      setSelected(stopList)
  }, [stopList]);

  const handleToggleGroup = (groupName, isOpen) => {
    if (isOpen) {
      const newValue = openGroups.filter((name) => name !== groupName);
      setOpenGroups(newValue);
      return;
    }

    setOpenGroups([...openGroups, groupName]);
  };


  const handleChange = (options, instructions) => (e) => {
    setSelected(options)
    setFilial('stopList', id)(e)
  };

  return (
    <Select
      isLoading={isLoading}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
      isMulti
      isSearchable
      closeMenuOnSelect={false}
      options={goods}
      placeholder="Товары"
      noOptionsMessage={() => 'Нет товаров'}
      onChange={(e) => handleChange()(e)}
      value={selected}
      hideSelectedOptions={false}
      components={{ 
        Option, 
        Group, 
        MultiValue,
      }}
      styles={{ ...selectStyles }}
      innerProps={{ openGroups, handleToggleGroup }}
    />
  );
};
