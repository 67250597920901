import React from 'react';
import cn from 'classnames';

const Portlet = ({ title, fit = false, children, style, right }) => {
    return (
        <div style={style} className="kt-portlet">
            {
                title && (
                    <div className="kt-portlet__head" style={{ backgroundColor: '#fff'}}>
                        <div className="kt-portlet__head-label">
                           
                            <h3 className="kt-portlet__head-title">
                                { title }
                            </h3>
                        </div>
                        {right && <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>{right}</div>}
                    </div>
                )
            }
            <div className={cn({ 'kt-portlet__body': true, 'kt-portlet__body--fit': fit })}>
                { children }
            </div>
        </div>	
    );
};

export default Portlet;