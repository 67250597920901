import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';
import { setStatFilters, resetStatFilters, fetchPopGoodsStat } from '../../action/statistics';
import { fetchCategories } from '../../action/orders';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';

const styles = {
    loading: {
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center'
    }
}


class Table extends Component {
    renderGood = (good, index) => {
        return (
            <tr key={index}>
                <td>
                    <span >
                        <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                                <img alt={good.data.title} src={good.data.image}/>
                            </div>
                            <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name">{good.data.title}</span>
                            </div>
                        </div>
                    </span>
                </td>
                <td>{this.props.categories[good.category]}</td>
                <td>{good.data.weight} гр.</td>
                <td>{good.price} руб.</td>
                <td className="kt-align-right kt-font-brand kt-font-bold">{good.count}</td>
            </tr>
        );  
    }

    render() {
        if (!this.props.items || this.props.items.length === 0) {
            return 'По данным критериям нет товаров';
        }

        return (
           
            <div className="kt-widget11">
                <div className="table-responsive">					 
                    <table className="table">
                        <thead>
                            <tr>
                                <td style={{ width: '30%' }}>Наименование</td>
                                <td style={{ width: '25%' }}>Категория</td>
                                <td style={{ width: '15%' }}>Вес</td>
                                <td style={{ width: '15%' }}>Цена</td>
                                <td style={{ width: '15%' }} className="kt-align-right">Кол-во заказов</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.items.map(this.renderGood)
                            }
                        </tbody>									     
                    </table>
                </div>
            </div>
        );
    }
}

class PopGoods extends Component {
    componentDidMount() {
       this.props.fetchCities();
       this.props.fetchCategories();
       this.props.fetchPopGoodsStat();
    }

    setFilters = (field) => (data) => {
        const { filters } = this.props.popGoods;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setStatFilters('popGoods', updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props.popGoods;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setStatFilters('popGoods', updated);
    }

    resetFilter = () => {
        this.props.resetStatFilters('popGoods');
        this.props.fetchPopGoodsStat();
    }

    onSearch = () => {
        this.props.fetchPopGoodsStat();
    }

    isDisabled = () => {
        const { popGoods } = this.props
        const { filters } = popGoods;

        if (filters.createdStartDate && filters.createdEndDate) {
            return false;
        }
        return true;
    }

    render() {
        const { popGoods, loadingCategories, categories } = this.props
        const { result, loading, filters } = popGoods;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Популярные товары
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="dateBlock" style={{ marginRight: 10 }}>
                            <DateInput
                                disabled={loading || loadingCategories}
                                placeholderText="Дата от"
                                selected={filters.createdStartDate}
                                onChange={this.onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                disabled={loading || loadingCategories}
                                placeholderText="Дата до"
                                selected={filters.createdEndDate}
                                onChange={this.onChangeDateInput('createdEndDate')}
                                maxDate={moment(filters.createdStartDate).add({ days: 30 }).toDate()}
                                required={filters.createdStartDate}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <Select
                                isLoading={this.props.loadingCity || loading}
                                isDisabled={this.props.loadingCity || loading}
                                value={filters.city}
                                closeMenuOnSelect={true}
                                options={this.props.cities.map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                onChange={this.setFilters('city')}
                                placeholder="Город"
                                noOptionsMessage={() => 'Нет городов'}
                            />
                        </div>
                        <button disabled={loading || this.isDisabled() || loadingCategories} style={{ marginLeft: 10, marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                        <button disabled={loading || loadingCategories} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    {
                        loading || !result ? (
                            <div style={styles.loading}><Loader /></div>
                        ) : (
                            <Table categories={categories} items={this.props.popGoods.result}/>
                        )
                    }
                   
                </div>
            </div>
                        
        );
    }
}

const mapStateToProps = state => {
    const categs = {};

    for (let i = 0; i < state.goods.categories.length; i++) {
        categs[state.goods.categories[i].id] = state.goods.categories[i].title;
    }

    return ({
        popGoods: state.statistics.popGoods,
        cities: state.handbook.cities,
        loadingCity: state.loaders.cities,
        categories: categs,
        loadingCategories: state.loaders.categories
    });
};

const mapDispatchToProps = {
    fetchPopGoodsStat: () => fetchPopGoodsStat(),
    fetchCities: () => fetchCities(true),
    setStatFilters: (name, filters) => setStatFilters(name, filters),
    resetStatFilters: (name) => resetStatFilters(name),
    fetchCategories: () => fetchCategories()
}

export default connect(mapStateToProps, mapDispatchToProps)(PopGoods);