import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchChanels } from '../../action/messages';
import Loader from '../../components/utils/Loader';
import ErrorContainer from '../../components/utils/ErrorContainer';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const icons = {
    device: { icon: 'la la-mobile', color: 'kt-font-brand' },
    city: { icon: 'flaticon-placeholder-3', color: 'kt-font-warning' },
    users: { icon: 'flaticon2-group', color: 'kt-font-success' },
    all: { icon: 'la la-mobile', color: 'kt-font-danger' },
    user: { icon: 'flaticon2-user', color: 'kt-font-success' },
    unregistered: { icon: 'flaticon-users-1', color: 'kt-font-success' }
}

export class Chanels extends Component {
    componentDidMount() {
        this.props.fetchChanels();
    }

    renderItem = (item) => {
        return (
            <div key={item.id} className="kt-notification__item">
                <div className="kt-notification__item-icon">
                    <i className={`${icons[item.key] ? icons[item.key].icon : 'la la-mobile'} ${icons[item.key] ? icons[item.key].color : 'kt-font-brand'}`}></i>
                </div>
                <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">
                        {item.title}
                    </div>
                </div>
                {
                    item.key === 'user' && (
                        <div>
                            <button type="button" className="btn btn-danger btn-sm">Удалить</button>
                        </div>
                    )
                }
            </div>
        );
    }   

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        return (
            <div className="kt-grid__item kt-grid__item--fluid    kt-portlet    kt-inbox__list kt-inbox__list--shown" id="kt_inbox_list" style={{ display: 'flex' }}>
                <div className="kt-portlet__head">
                    <div className="kt-inbox__toolbar kt-inbox__toolbar--extended">
                        <div className="kt-inbox__actions kt-inbox__actions--expanded">
                            <div className="kt-inbox__check">
                            <h5 style={{ marginBottom: 0 }}>Каналы ({ this.props.chanels.length })</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">    
                    <div className="kt-notification">
                        {
                            this.props.chanels.map(this.renderItem)
                        }
                    </div>
                </div>
                <ErrorContainer field="chanels" style={{ margin: 15 }} hasCloseButton={false}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loadingChanel: state.loaders.chanels,
    chanels: state.messages.chanels
});

const mapDispatchToProps = {
    fetchChanels: () => fetchChanels()
}

export default connect(mapStateToProps, mapDispatchToProps)(Chanels)
