import { loadersSelector, ipsSelector } from './root';
import { propNameSelector } from '../utils';

export const ipsListSelector = propNameSelector(ipsSelector, 'list');
export const pagingIpsSelector = propNameSelector(ipsSelector, 'paging');
export const sortingIpsSelector = propNameSelector(ipsSelector, 'sorting');
export const countIpsSelector = propNameSelector(ipsSelector, 'count');
export const loaderIpsSelector = propNameSelector(loadersSelector, 'ips');
export const loaderUpdateIpsSelector = propNameSelector(loadersSelector, 'updateIps');
export const loaderDeleteIpsSelector = propNameSelector(loadersSelector, 'deleteIps');
