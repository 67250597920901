export const MEASURES = {
    gramm: 'гр.',
    liter: 'л.',
    pc: 'шт.',
};

export const CONDITIONS = {
    equal: '=',
    plus: '+',
    minus: '-',
    multiply: '*'
};

export const TABS = [
    { key: 'regionPrices', title: 'Региональные цены', icon: 'flaticon-map-location' },
    { key: 'available', title: 'Доступность', icon: 'flaticon-earth-globe' },
    { key: 'modifications', title: 'Модификации', icon: 'flaticon2-gear' },
];