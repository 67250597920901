import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';
import { urls, baseUrl } from '../constants/app';
import request from './utils';
import moment from 'moment';
import axios from 'axios';
import { setError, resetError } from './errors';
import { createFormData } from '../utils/common';
axios.defaults.baseURL = baseUrl;

const namespace = 'DOCUMENTATION';

export const setDocumentation = createAction(makeActionType(namespace, ['SET']));
export const setDocumentationDetail = createAction(makeActionType(namespace, ['SET', 'DETAIL']));
export const setPagingDocumentation = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingDocumentation = createAction(makeActionType(namespace, ['SET', 'SORTING']));
export const setFiltersDocumentation = createAction(makeActionType(namespace, ['SET', 'FILTERS']));
export const setCountDocumentation = createAction(makeActionType(namespace, ['SET', 'COUNT']));

export function fetchDocumentation(dataParams = {}) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'documentation', value: true });
        dispatch(resetError('documentation'));

        const documentationState = getState().documentation;
        const { paging, sorting, filters, } = documentationState;
        const params = {...dataParams};

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                dataParams[key] = filters[key];
                params[key] = filters[key];
            }
        });

        params['_start'] = paging.start;
        params['_limit'] = paging.limit;
        params['_sort'] = `${sorting.field}:${sorting.type}`;

        if (filters.createdStartDate) {
            dataParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        if (filters.createdEndDate) {
            dataParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        request({ method: 'get', url: urls.documentation+'/count', params: dataParams })
            .then(result => {
                dispatch(setCountDocumentation(result));
                return;
            })
            .then(() => {
                return request({ method: 'get', url: urls.documentation, params }).then((response) => {
                    dispatch(setDocumentation(response))
                    dispatch({ type: 'SET_LOADER', field: 'documentation', value: false });
                })
            })
            .catch(error => {
                dispatch(setError(error, 'documentation'));
                dispatch({ type: 'SET_LOADER', field: 'documentation', value: false });
            });
        }
}

export function fetchDocumentationDetail(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'documentation', value: true });
        dispatch(resetError('documentation'));
       
        try {
            const result = await request({ method: 'get', url: `${urls.documentation}/${id}` });
            dispatch(setDocumentationDetail(result));
            dispatch({ type: 'SET_LOADER', field: 'documentation', value: false });
        } catch(error) {
            dispatch(setError(error, 'documentation'));
            dispatch({ type: 'SET_LOADER', field: 'documentation', value: false });
        }
    };
}

export function addDocumentation(values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: true });
        const data = createFormData(values);

        try {
            const result = await request({ method: 'post', url: urls.documentation, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: false });
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'documentationAdd'));
            dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: false });
            return false;
        }
    }
}

export function updateDocumentation(id, values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: true });
        const data = createFormData(values);
        
        try {
            const result = await request({ method: 'put', url: `${urls.documentation}/${id}`, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: false });
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'documentationAdd'));
            dispatch({ type: 'SET_LOADER', field: 'documentationAdd', value: false });
            return false;
        }
    }
}

export function deleteDocumentation(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'documentation', value: true });
        try {
            await request({ method: 'delete', url: `${urls.documentation}/${id}` });
            dispatch(fetchDocumentation());
        } catch(error) {
            dispatch(setError({ status: 507 }, 'documentation'));
            dispatch({ type: 'SET_LOADER', field: 'documentation', value: false });
        }
    }
}