import { createAction } from 'redux-actions';
import { urls } from '../constants/app';
import { makeActionType } from '../utils';
import { resetError, setError } from './errors';
import request from './utils';

const namespace = 'YOOKASSA';

export const setKassaAccounts = createAction(makeActionType(namespace, ['SET']));
export const setKassaAccount = createAction(makeActionType(namespace, ['SET', 'ACCAUNT']));
export const addKassaList = createAction(makeActionType(namespace, ['ADD', 'ACCAUNT']));
export const deleteKassaAction = createAction(makeActionType(namespace, ['DELETE', 'ACCAUNT']));
export const setPagingKassa = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingKassa = createAction(makeActionType(namespace, ['SET', 'SORTING']));
export const setCountKassa = createAction(makeActionType(namespace, ['SET', 'COUNT']));
export const setDetailKassa = createAction(makeActionType(namespace, ['SET', 'DETAIL']));
export const setActiveModalKassaAction = createAction(makeActionType('LAYOUT', ['SET', 'MODAL']));

export function fetchKassaAccounts(withoutLoader = false) {
    return async (dispatch) => {
        !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'yookassa', value: true });
        dispatch(resetError('yookassa'));
        try {
            const accounts = await request({ method: 'get', url: urls.yookassa });
            dispatch(setKassaAccounts(accounts))
            dispatch(setCountKassa(accounts.length));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'yookassa', value: false });
        } catch(error) {
            dispatch(setError(error, 'yookassa'));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'yookassa', value: false });
        }
    }
}

export function addKassaAccount(data) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'addKassa', value: true });
        try {
            const account = await request({ method: 'post', url: urls.yookassa, data });
            if (account) {
                dispatch({ type: 'SET_LOADER', field: 'addKassa', value: false });
                dispatch(addKassaList(account));
                return account.id;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'addKassa'));
            dispatch({ type: 'SET_LOADER', field: 'addKassa', value: false });
            return null;
        }
    }
}

export function updateKassaAccount(id, data) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'updateKassa', value: id });
        dispatch(resetError('yookassa'));
        try {
            const account = await request({ method: 'put', url: `${urls.yookassa}/${id}`, data });
            dispatch(setKassaAccount(account));
            dispatch({ type: 'SET_LOADER', field: 'updateKassa', value: null });
            return account.id;
        } catch(error) {
            dispatch(setError(error, 'yookassa'));
            dispatch({ type: 'SET_LOADER', field: 'updateKassa', value: null });
            return null;
        }
    }
}

export function deleteKassaAccount(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteKassa', value: id });
        dispatch(resetError('yookassa'));
        try {
            await request({ method: 'delete', url: `${urls.yookassa}/${id}` });
            dispatch(deleteKassaAction(id));
            dispatch({ type: 'SET_LOADER', field: 'deleteKassa', value: null });
        } catch(error) {
            dispatch(setError({ status: 507 }, 'yookassa'));
            dispatch({ type: 'SET_LOADER', field: 'deleteKassa', value: null });
        }
    }
}
