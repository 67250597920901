import { setPhoneCodes } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [
    forAction(setPhoneCodes, identityPayload),
  ],
  initialState,
);
