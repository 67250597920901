import React from 'react';
import { ParamsGood } from './ParamsGood';
import { Tabs } from './Tabs';

export function DetailContent() {
    return (
        <div className="row">
            <div className="col-xl-3">
                <ParamsGood />
            </div>
            <div className="col-xl-9">
                <Tabs />
            </div>
        </div>
    );
}