import React, {Component} from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../action/user';
import { clientPages } from '../constants/page';
import {Route, Redirect} from 'react-router-dom';
import Content from '../components/layout/Content';

class Clients extends Component {
    render() {
        return (
            <div>
                { 
                    clientPages.map(page => (
                        <Route
                            key={page.name}
                            exact
                            path={page.path}
                            render={props => {
                                if (page.access && !page.access.includes(this.props.role.id)) {
                                    return <Redirect to={{ pathname: "/clients" }} />;
                                }

                                if (props.match.params.id && !/\d+/.test(props.match.params.id)) {
                                      return null;
                                }
                                   
                                const Component = page.component;
                                return (
                                    <Content subheader={true} title={page.title} fit={page.fit}>
                                        <Component {...props}/>
                                    </Content>
                                );
                            }}
                        />
                    )) 
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users.list,
    loading: state.loaders.content,
    role: state.user.info.role
});

const mapDispatchToProps = {
    fetchUsers: () => fetchUsers()
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);