import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAdditivesWOK } from "../../action/neworder";

const AdditivesWOKNewOrder = ({ index, closeAdditivesWOK }) => {
    const settings = useSelector(state => state.settings.init.categories);
    const goodsChek = useSelector(state => state.good.list);
    const point = useSelector(state => state.neworders.point);
    const isAggPrice = useSelector(state => state.neworders.isAggPrice);
    const [AdditivesWOK, setAdditivesWOK] = useState('')

    const dispatch = useDispatch();

    const AdditivesCategories = [
        {id: settings.mainWok, title: 'Лапша ВОК'}, 
        {id: settings.meatWok, title: 'Мясо в ВОК'}, 
        {id: settings.toppingWok, title: 'Топпинги в ВОК'}, 
        {id: settings.souceWok, title: 'Соус в ВОК'}
    ]

    const filterGoods = useCallback(() => {
        const categoryIds = AdditivesCategories.map(category => category.id);
        setAdditivesWOK(
            goodsChek.filter((item) => categoryIds.includes(item.category.id))
            .sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            .filter((item) => { //проверка на доступность позиции в городе
                const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                return !disabledCityIds.some((id) => parseInt(id) === point.city.id);
            })
            .map((item) => //проверка региональных цен
                item.regionprices.find((regionprices) => 
                    regionprices.cities.some((city) => parseInt(city.id) === point.city.id))
                ?
                { ...item, 
                    price: item.price + item.regionprices.find((regionprices) => 
                        regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).price, 
                    oldPrice: item.oldPrice + item.regionprices.find((regionprices) => 
                        regionprices.cities.some((city) => parseInt(city.id) === point.city.id)).oldPrice
                } 
                : 
                item                   
            )
        );
    }, [goodsChek, AdditivesCategories, point.city.id]);

    useEffect(() => {
        filterGoods();
    }, [filterGoods])

        return(
            <div className="popup_additives"
            onMouseDown={((e) => e.preventDefault())}
            onBlur={closeAdditivesWOK}
            >
                {isEmpty(AdditivesCategories) ? <li>Добавок нет</li> : AdditivesCategories.map((category) => (
                    <div key={category.id} className="popup_additives_category">
                        <span>{category.title}</span>
                        <div className="popup_additives_buttons_div">
                            {isEmpty(AdditivesWOK) ? <li>Добавок нет</li> : AdditivesWOK.map((item) => (
                                item.category.id === category.id &&
                                <button 
                                key={item.id} 
                                className={`popup_additives_button ${(!point || point.stopList.some((el) => el.value === item.id)) ? 'unactived' : ''}`} 
                                onMouseDown={((e) => e.preventDefault())}
                                onBlur={closeAdditivesWOK}
                                onClick={(() => {
                                    if(point && !point.stopList.some((el) => el.value === item.id))
                                        dispatch(addAdditivesWOK(index, item, settings, item.id))
                                })}
                                >                                    
                                    {item.hot && <div className="popup_additives_hot_circle"></div>}
                                    <span className="neworder_good_button_text"> {item.name} </span>
                                    <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.8rem' }}> {`${!isAggPrice ? item.price : item.oldPrice} ₽`} </span>
                                </button> 
                            ))}
                        </div>
                    </div>
                ))}
                    
            </div>
        );
    
}

export default AdditivesWOKNewOrder