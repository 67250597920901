import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import InfoBox from '../../../components/utils/InfoBox';
import { fromStore } from '../../../selectors';
import { fetchCities } from '../../../action/handbook';
import Loader from '../../../components/utils/Loader';
import { isEmpty } from 'lodash';

const styles = {
    loading: {
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const CategoryStop = ({ category }) => {
    const cities = useSelector(fromStore.citiesListSelector);
    const { disabledCities } = category;
    const dCities = disabledCities ? cities.filter((item) => disabledCities.split(',').includes(String(item.id))) : [];

    return (
        <>
             <div className="alert alert-outline-primary" role="alert">
                <div className="alert-text">Для редактирования доступности категории необходимо перейти в редактирование категории</div>
            </div>
            {disabledCities && (
                 <div className="kt-widget1__item">
                    <div className="kt-widget1__info">
                        <h3 className="kt-widget1__title">Категория «{category.title}» недоступна в городах:</h3>
                        <span className="kt-widget1__desc">
                            {dCities.map((item) => <span key={item.id} style={{marginRight: '5px' }} className="kt-badge kt-badge--danger kt-badge--inline">{item.name}</span>)}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

const GoodStop = ({ detail }) => {
    const cities = useSelector(fromStore.citiesListSelector);
    const { disabledCities } = detail;

    const dCities = disabledCities ? cities.filter((item) => disabledCities.split(',').includes(String(item.id))) : [];

    return (
        <>
            {disabledCities && (
                 <div className="kt-widget1__item">
                    <div className="kt-widget1__info">
                        <h3 className="kt-widget1__title">Товар «{detail.name}» недоступен в городах:</h3>
                        <span className="kt-widget1__desc">
                            {dCities.map((item) => <span key={item.id} style={{marginRight: '5px' }} className="kt-badge kt-badge--dark kt-badge--inline">{item.name}</span>)}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export function AvailableGood() {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);
    const cities = useSelector(fromStore.citiesListSelector);
    const loadingCities = useSelector(fromStore.loaderCitiesSelector);
    const hasStopCategory = detail.category.disabledCities;
    const hasStopGood = detail.disabledCities;

    useEffect(() => {
        if ((hasStopCategory || hasStopGood) && isEmpty(cities)) {
            dispatch(fetchCities(true, true));
        }

    }, [cities, dispatch, hasStopCategory, hasStopGood]);

    if ((hasStopGood || hasStopCategory) && loadingCities) {
        return <div style={styles.loading}><Loader /></div>;
    }

    if (!hasStopCategory && !hasStopGood) return (
        <div style={{ padding: '20px' }}>
            <InfoBox title="Доступен везде" subtitle="Все города" />
        </div>
    );

    return (
        <div>
            <div className="kt-widget1">
                { hasStopCategory && <CategoryStop category={detail.category} /> }
                { hasStopGood && <GoodStop detail={detail} /> }
            </div>
        </div>
    );
}