import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export const TimeInput = (props) => {
    return (
      <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Время"
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        {...props}
      />
    );
  };
