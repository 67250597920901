import React, { Component } from 'react';
import { withRouter, Link  } from 'react-router-dom';
import moment from 'moment';

class RatingsList extends Component {
    renderThead() {
        const { middle } = this.props;

        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Пользователь</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '110px' }}>
                            Дата
                        </span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '140px' }}>{`Кухня${middle.kitchen ? ` (${middle.kitchen} из 5)` : ''}`}</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '140px' }}>{`Доставка${middle.delivery ? ` (${middle.delivery} из 5)` : ''}`}</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '140px' }}>{`Сервис${middle.service ? ` (${middle.service} из 5)` : ''}`}</span>
                    </th>
                </tr>
            </thead>
        );
    }

    renderBody() {
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Оценок не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.items.map(item => {
                        return (
                            <tr key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>
                                    <Link to={`/clients/${item.user.id}`}>{item.user.username}</Link>
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '110px' }}>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '140px' }}>
                                    {
                                        Array(+item.kitchen).fill(1).map((item, index) => {
                                            return <img alt="rating" key={index} src="/images/favorite.png" width="20"/>;
                                        })
                                    }
                                </span>
                            </td>

                            <td className="kt-datatable__cell">
                                <span style={{ width: '140px' }}>
                                    {
                                        Array(+item.delivery).fill(1).map((item, index) => {
                                            return <img alt="rating" key={index} src="/images/favorite.png" width="20"/>;
                                        })
                                    }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '140px' }}>
                                {
                                    Array(+item.service).fill(1).map((item, index) => {
                                        return <img alt="rating" key={index} src="/images/favorite.png" width="20"/>;
                                    })
                                }
                                </span>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(RatingsList);