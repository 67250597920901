const initialState = {
  cashbackLogsUser: {
    logs: [],
    count: 0,
    paging: {
      start: 0,
      limit: 10
    },
  },
};

export default function logs(state = initialState, action) {
  switch(action.type) {
    case 'SET_CASHBACKLOGS_USER': {
      return {
        ...state,
        cashbackLogsUser: {
          ...state.cashbackLogsUser,
          logs: action.logs
        }
      };
    }
    case 'SET_CASHBACKLOG_USER_COUNT': {
      return {
        ...state,
        cashbackLogsUser: {
              ...state.cashbackLogsUser,
              count: action.count
          }
      };
    }
    case 'SET_CASHBACKLOG_USER_PAGING': {
      return {
        ...state,
        cashbackLogsUser: {
          ...state.cashbackLogsUser,
          paging: action.paging
        }
      };
    }
    default: return state;
  }
}