import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import InfoBox from '../../../components/utils/InfoBox';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { getNewPrice } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { deleteRegionPrice, setActiveModalAction, updateRegionPrice } from '../../../action';
import swal from 'sweetalert';

export function RegionPrices() {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);
    const loaderUpdate = useSelector(fromStore.loaderUpdateRegionPriceSelector);
    const loaderDelete = useSelector(fromStore.loaderDeleteRegionPriceSelector);

    if (isEmpty(detail.regionprices)) return (
        <div style={{ padding: '20px' }}>
            <InfoBox title="Основная цена" subtitle="Во всех городах единая цена" />
        </div>
    );

    const onOpenModalRegionPrice = (id) => (e) => {
        e.stopPropagation();
        dispatch(setActiveModalAction({ field: 'regionPrice', value: id }));
    };

    const onToogleActive = (id, active) => () => {
        const data = { active: !active, goodId: detail.id };
        dispatch(updateRegionPrice(id, data))
    };

    const onDelete = (id) => () => {
        swal({
            title: `Удаление цены`,
            text: `Вы действительно хотите удалить цену?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteRegionPrice(id, detail.id));
            }
        });
    }

    const header = (
        <thead className="kt-datatable__head">
            <tr className="kt-datatable__row">
                <th width="auto" className="kt-datatable__cell kt-datatable__cell--sort"><span>Город(а)</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Создано</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Обновлено</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Цена</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Старая цена</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span></span></th>
            </tr>
        </thead>
    );

    return (
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
            <table className="kt-datatable__table" style={{ width: '100%' }}>
                {header}
                <tbody className="kt-datatable__body ps ps--active-y">
                    {detail.regionprices.map((item) => {
                        const isLoadingUpdate = loaderUpdate === item.id;
                        const isLoadingDelete = loaderDelete === item.id;
                        const disabled = isLoadingDelete && isLoadingUpdate;
                    
                        return (
                            <tr key={`${item.id}_regprices`} className="kt-datatable__row">
                                <td width="auto" className="kt-datatable__cell">
                                    <span>
                                    {item.cities.map((item) => <span key={item.id} style={{marginRight: '2px'}} className="kt-badge kt-badge--brand kt-badge--inline">{item.name}</span>)}
                                    </span>
                                </td>
                                <td width="145px" className="kt-datatable__cell"><span>{moment(item.created_at).format('DD.MM.YY в HH:mm')}</span></td>
                                <td width="145px" className="kt-datatable__cell"><span>{moment(item.updated_at).format('DD.MM.YY в HH:mm')}</span></td>
                                <td width="145px" className="kt-datatable__cell"><span>{getNewPrice(detail.price, item.condition, item.price, item.persent)} ₽</span></td>
                                <td width="145px" className="kt-datatable__cell">
                                    <span>{item.oldPrice ? `${getNewPrice(detail.oldPrice, item.condition, item.oldPrice, item.persent)} ₽` : '-'}</span>
                                </td>
                                <td align='right' width="145px" className="kt-datatable__cell">
                                    <button
                                        disabled={disabled}
                                        style={{ marginRight: '3px' }}
                                        onClick={onToogleActive(item.id, item.active)}
                                        data-tip={item.active ? 'Выключить' : 'Включить'}
                                        className={cn("btn btn-sm btn-icon btn-icon-md", {
                                            'btn-danger': !item.active,
                                            'btn-success': item.active,
                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': isLoadingUpdate
                                        })}>
                                        {!isLoadingUpdate && <i className="la la-power-off"></i>}
                                    </button>
                                    
                                    <button disabled={disabled} onClick={onOpenModalRegionPrice(item.id)} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                                    <button
                                        disabled={disabled}
                                        onClick={onDelete(item.id)}
                                        data-tip="Удалить" 
                                        className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': isLoadingDelete
                                        })}>
                                            {!isLoadingDelete && <i className="la la-trash"></i>}
                                        </button>
                                </td>
                            </tr>
                        );
                    })
                }               
                </tbody>
            </table>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </div>
    );
}