export const styles = {
    loading: {
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    tableLoading: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
    }
};