import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th width="80px" className="kt-datatable__cell"><span>ID</span></th>
            <th width="80px" className="kt-datatable__cell"><span>Превью</span></th>
            <th width="auto" className="kt-datatable__cell"><span>Название</span></th>
            <th width="165px" className="kt-datatable__cell"><span>Цена</span></th>
            <th width="165px" className="kt-datatable__cell"><span>Старая цена</span></th>
            <th width="165px" className="kt-datatable__cell"><span>Артикул</span></th>
            <th width="165px" className="kt-datatable__cell"><span>Вес/Объем</span></th>
            <th style={{ textAlign: 'right' }} width="180px" className="kt-datatable__cell"><span>Действия</span></th>
        </tr>
    </thead>
);