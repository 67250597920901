import { handleActions } from 'redux-actions';

export const identityPayload = (state, action) => {
  if (action.payload !== undefined) return action.payload;
  return state;
};

export const identityClean = (initial) => () => initial;

export const forAction = (action, handler) => [action, handler];
export const forCleanAction = (action, handler) => [action, handler];

export const createCleanActions = (actions, initial) => actions.map(
  (action) => forCleanAction(action, identityClean(initial)),
);

export const createReducer = (actions, initial, cleanActions = []) => {
  const cleanActionsHandlers = createCleanActions(cleanActions, initial);

  return handleActions(new Map([...actions, ...cleanActionsHandlers]), initial);
};

export const propNameSelector = (selector, field) => (state) => selector(state)[field];

export const makeActionType = (namespace, items) => `SB/${namespace}/${items.join('/')}`;
