import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fromStore } from '../../selectors';
import { fetchYumaposGoodMapsByGoodId, addYumaposGoodMapUpdateList } from '../../action';

export function YumaposGoodsMapInput({ good }) {
  const { id, modifications } = good;
  const dispatch = useDispatch();
  const yumaposgoodsmap = useSelector(fromStore.yumaposgoodsmapListSelector);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    dispatch(fetchYumaposGoodMapsByGoodId(id));
  }, [dispatch, id]);
  
  useEffect(() => {
    const initialValues = {};
    if (modifications.length === 0) {
      // Товар без модификаций
      const mapping = yumaposgoodsmap.find(m => m.good === id && m.modification === null);
      initialValues['no_modifications'] = mapping ? mapping.goodIdYumapos : '';
    } else {
      // Товар с модификациями
      modifications.forEach(mod => {
        const mapping = yumaposgoodsmap.find(m => m.good === id && m.modification === mod.id);
        initialValues[mod.id] = mapping ? mapping.goodIdYumapos : '';
      });
    }
    setInputValues(initialValues);
  }, [yumaposgoodsmap, modifications, id]);

  const handleInputChange = (modId, value) => {
    const inputKey = modId || 'no_modifications'; // Установим ключ для состояния
    setInputValues(prev => ({ ...prev, [inputKey]: value }));

    // Проверяем, есть ли уже запись в list для товара без модификаций
    const existingMapping = yumaposgoodsmap.find(m => m.good === id && (modId === null ? m.modification === null : m.modification === modId));

    // Если запись есть, обновляем объект в updateList, иначе добавляем новый
    if (existingMapping) {
      dispatch(addYumaposGoodMapUpdateList({
        id: existingMapping.id, // Используем существующий id из list
        goodId: id,
        goodIdYumapos: value,
        modification: modId // Устанавливаем modification в null для товара без модификаций
      }));
    } else {
      // Если записи нет, добавляем новый объект в updateList
      dispatch(addYumaposGoodMapUpdateList({
        id: null, // Устанавливаем id в null, так как это новая запись
        goodId: id,
        goodIdYumapos: value,
        modification: modId
      }));
    }
  };

  return (
    <>
      {modifications.length === 0 ? (
        <div className="form-group">
          <label htmlFor="g-priority" className="form-control-label">Id в системе Umapos для товара без модификаций</label>
          <input
            type="text" 
            value={inputValues['no_modifications'] || ''}
            onChange={(e) => handleInputChange(null, e.target.value)} // Передаем null в качестве modId
            className="form-control" 
            id="g-priority" 
          />
        </div>
      ) : (
        modifications.map(mod => (
          <div className="form-group">
            <label>Id в системе Umapos для товара с модификацией {mod.title}</label>
            <input
              type="text" 
              value={inputValues[mod.id] || ''}
              onChange={(e) => handleInputChange(mod.id, e.target.value)}
              className="form-control" 
              id="g-priority" 
            />
          </div>
        ))
      )}
    </>
  );
}