const initialState = {
  list: [],
  updateList: [],
};

export default function yumaposGoodMap(state = initialState, action) {
  switch (action.type) {
    case 'SET_YUMAPOSGOODMAP_LIST': {
      return {
        ...state,
        list: action.result,
      };
    }
    case 'SET_YUMAPOSGOODMAP_UPDATELIST': {
      return {
        ...state,
        updateList: action.result,
      };
    }
    case 'ADD_YUMAPOSGOODMAP_UPDATELIST': {
      const { id, goodId, goodIdYumapos, modification } = action.payload;
      let updatedList = [...state.updateList];

      // Проверяем, существует ли уже объект с таким же goodId и modification в updateList
      const existingIndex = updatedList.findIndex(item => item.good === goodId && item.modification === modification);

      if (existingIndex !== -1) {
        // Если объект существует, обновляем его
        updatedList[existingIndex] = { ...updatedList[existingIndex], goodIdYumapos };
      } else {
        // Если объекта нет, добавляем новый
        const newItem = {
          id: id, // Если list пуст, id будет null
          goodIdYumapos,
          good: goodId,
          modification,
        };
        updatedList.push(newItem);
      }

      return {
        ...state,
        updateList: updatedList,
      };
    }
    default:
      return state;
  }
}