import React from 'react';
import { DetailCard } from './DetailCard';
import { DetailContent } from './DetailContent';

export function DetailGood() {
    return (
        <>
            <DetailCard />
            <DetailContent />
        </>
    );
}