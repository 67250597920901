const initialState = {
    list: [],
    detail: null,
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    filters: {
      city: null,
      point: null,
      status: null,
      createdStartDate: null,
      createdEndDate: null,
    },
    count: 0
};

export default function orders(state = initialState, action) {
  switch(action.type) {
    case 'SET_ORDERS': {
          return {
              ...state,
              list: action.orders
          };
    }
    case 'SET_ORDERS_FILTERS': {
      return {
          ...state,
          filters: action.filters
      };
    }
    case 'SET_ORDERS_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
    }
    case 'SET_ORDERS_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    case 'SET_ORDER': {
      return {
          ...state,
          detail: action.order
      };
    }
    case 'UPDATE_ORDER_POINT': {
      if (action.point) {
          return {
              ...state,
              list: state.list.map(el =>
                  el.id === action.id
                      ? {
                          ...el,
                          point: action.point,
                          zone: action.zone,
                          city: action.city
                        }
                      : el
              )
          };
      }

      if (action.status) {
          return {
              ...state,
              list: state.list.map(el =>
                  el.id === action.id
                      ? {
                          ...el,
                          status: action.status
                        }
                      : el
              )
          };
      }

      if (!action.point && !action.status) {
        return {
            ...state,
            list: state.list.map(el =>
                el.id === action.id
                    ? {
                        ...el,
                        point: action.point,
                        zone: action.zone,
                        city: action.city
                      }
                    : el
            )
        };
    }
    return state;
  }
  default: return state;
  }
}