import { deleteSliderAction, addSlidersList, setSlider, setSliders } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [
    forAction(setSliders, identityPayload),
    forAction(setSlider, (state, { payload: good }) => {
      const index = state.findIndex((item) => item.id === good.id);
      const updated = [...state];
      updated[index] = { ...good };
      return updated;
    }),
    forAction(addSlidersList, (state, { payload: good }) => {
      const updated = [...state, good];
      return updated;
    }),
    forAction(deleteSliderAction, (state, { payload: id }) => {
      return state.filter((item) => item.id !== id);
    })
  ],
  initialState,
);
