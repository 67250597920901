import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import Input from '../utils/Input';
import { setAddCity, addZone, fetchCities } from '../../action/handbook';
import cn from 'classnames';
import Loader from '../utils/Loader';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl, Polygon, Placemark } from 'react-yandex-maps';
const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const reverseCoord = (coors) => {
    const clone = cloneDeep(coors);
    const result = clone[0].map(item => item.reverse());
    return [result];
}


class AddZone extends Component {
    state = {
        file: null,
        selected: null
    };
    componentDidMount() {
        this.props.fetchCities();
        const cityId = this.props.location.state && this.props.location.state.city;
        this.props.setAddCity({ city: cityId });
    }

    componentWillUnmount() {
        this.props.setAddCity({});
    }
    
    renderSubHeader() {
        const cityId = this.props.location.state && this.props.location.state.city;
        const currentCityId = cityId || this.props.addForm.city;

        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Добавить зону</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push(currentCityId ? `/handbook/cities/${currentCityId}` : '/handbook/cities' )} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidZone() {
        const { city, point, place, deliveryPrice, name, minDeliveryPrice, deliveryTime, deliveryCode, minDeliveryOrder } = this.props.addForm;
        if (city && point && place && deliveryPrice && name && minDeliveryPrice && deliveryTime && deliveryCode && minDeliveryOrder) return true;
        return false;
    }

    prepareCoords(coords) {
        if (!coords) return '';
        try {
            // eslint-disable-next-line
            const parsed = JSON.parse(coords.replace((/  |\r\n|\n|\r/gm), ""));
            return parsed;
        } catch (err) {
            console.log('Некорректные координаты');
            return '';
        }
    }

    setCity = (field) => (e) => {
        const data = {...this.props.addForm};
       
        if (field === 'place') {
            const prepared = this.prepareCoords(e.target.value);
            data[field] = prepared;
        } else {
            data[field] = e.target.value;
        }

        this.props.setAddCity(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const cityId = this.props.location.state && this.props.location.state.city;
        const currentCityId = cityId || this.props.addForm.city;
        this.props.addZone(this.props.addForm).then(result => {
            if (result) {
               this.props.history.push(`/handbook/cities/${currentCityId}`)
            }
        });
    }

    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    setCoord = (value) => {
        const data = {...this.props.addForm};
        data['coord'] = value;
        this.props.setAddCity(data);
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    loadFile = (files) => {
        var file = files.target.files[0];
        if (!file) {
            const data = {...this.props.addForm};
            data.place = undefined;
            this.props.setAddCity(data);
            this.setState({ file: null, selected: null });
            return;
        };
        var reader = new FileReader();
        reader.onload = (e) => {
            this.setState({ file: JSON.parse(e.target.result) });
        };
        reader.readAsText(file);
    }

    setZone = (item) => (e) => {
        const data = {...this.props.addForm};
        const coord = item.geometry.coordinates;

        const prepared = this.prepareCoords(JSON.stringify(coord));
        data.place = prepared;
      
        this.props.setAddCity(data);
        this.setState({ selected: item.id });
    }

    render() {
        if (this.props.citiesLoader) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        const cityId = this.props.location.state && this.props.location.state.city;

        const currentCityId = cityId || this.props.addForm.city;
        const currentCity = this.props.cities.filter(item => item.id === +currentCityId)[0];

        const points = currentCity ? currentCity.points : [];
        const currentPoint = this.props.addForm.point ? points.filter(p => p.id === +this.props.addForm.point)[0] : null;
        const defaultCoord = currentCity ? currentCity.coord.split(',').map(item => +item) : [47.222127, 39.720367];
       
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <form onSubmit={this.onSubmit} className="kt-form">
                                <div className="kt-portlet__body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Город *</label>
                                                <select disabled={cityId} value={cityId ? cityId : this.props.addForm.city} onChange={this.setCity('city')} className="form-control">
                                                    <option value="">Выберите город</option>
                                                    {
                                                        this.props.cities.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Точка *</label>
                                                <select disabled={points.length === 0} value={this.props.addForm.point} onChange={this.setCity('point')} className="form-control">
                                                    <option value="">Выберите точку</option>
                                                    {
                                                        points.map((item, index) => (
                                                            <option key={index} value={item.id}>{this.renderAdress(item)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Название зоны"
                                                required={true}
                                                onChange={this.setCity('name')}
                                                value={this.props.addForm.name}
                                                placeholder="Название..."
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Код доставки"
                                                required={true}
                                                onChange={this.setCity('deliveryCode')}
                                                value={this.props.addForm.deliveryCode}
                                                placeholder="Код..."
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="Стоимость доставки"
                                                required={true}
                                                onChange={this.setCity('deliveryPrice')}
                                                value={this.props.addForm.deliveryPrice}
                                                placeholder="Стоимость..."
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                required={true}
                                                label="Бесплатно от"
                                                onChange={this.setCity('minDeliveryPrice')}
                                                value={this.props.addForm.minDeliveryPrice}
                                                placeholder="Сумма..."
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                required={true}
                                                label="Минимальная сумма заказа"
                                                onChange={this.setCity('minDeliveryOrder')}
                                                value={this.props.addForm.minDeliveryOrder}
                                                placeholder="Сумма..."
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                required={true}
                                                label="Время доставки"
                                                onChange={this.setCity('deliveryTime')}
                                                value={this.props.addForm.deliveryTime}
                                                placeholder="Время доставки..."
                                                type="number"
                                            />
                                        </div>
                                    </div>

                                    
                                    <div className="row" style={{ marginBottom: 20 }}>
                                        <div className="col-md-12">
                                            <div className="yaconstructor">
                                                <div className="yaconstructor_title">Импорт зон из Yandex Map Constructor</div>
                                                <input type="file" onChange={this.loadFile} />
                                                {this.state.file && (
                                                    <div className="yaconstructor_info">
                                                        <div><span>Город:</span> {this.state.file.metadata.name}</div>
                                                        <div>Выберите зону:</div>
                                                        <div className="yaconstructor_zone">
                                                            {this.state.file.features.filter(item => item.geometry.type === 'Polygon').map(item => {
                                                                return <div className={cn({ 'active_zone': item.id === this.state.selected })} onClick={this.setZone(item)} key={item.id}>{item.properties.description}</div>;
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                disabled
                                                label="Координаты зоны"
                                                required={true}
                                                onChange={this.setCity('place')}
                                                value={JSON.stringify(this.props.addForm.place)}
                                                placeholder="Координаты..."
                                                textarea={true}
                                                rows={10}
                                            />
                                        </div>
                                    </div>
                               
                                </div>
                                <ErrorContainer field="addZone" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <button
                                            disabled={!this.isValidZone() || this.props.loading}
                                            type="submit"
                                            className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.loading })}>
                                            Добавить
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <YMaps
                            style={{ padding: '25px ', marginTop: -30 }}
                            query={{
                                apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                            }}
                            >
                            <Map
                                height="500px"
                                width="100%"
                                state={{ center: defaultCoord, zoom: 12, controls: [] }}
                            >
                                {
                                    this.props.addForm.place && (
                                        <Polygon
                                            geometry={reverseCoord(this.props.addForm.place)}
                                            options={{
                                                fill: "#56db40",
                                                fillOpacity: 0.6,
                                                stroke: "#56db40",
                                                strokeWidth: "1",
                                                strokeOpacity: 0.9
                                            }}
                                        />
                                    )
                                }
                                {
                                    currentPoint && (
                                        <Placemark geometry={currentPoint.coord.split(',')} />
                                    )
                                }
                               
                                <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>       
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    addForm: state.handbook.add,
    loading: state.loaders.addZone,
    cities: state.handbook.cities,
    citiesLoader: state.loaders.cities
});

const mapDispatchToProps = {
    setAddCity: (data) => setAddCity(data),
    addZone: (data) => addZone(data),
    fetchCities: () => fetchCities(true)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddZone);