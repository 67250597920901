export const baseUrl = '/api';
export const urls = {
    singIn: `/auth/local`,
    currentUser: `/users/me`,
    users: `/users`,
    register: `/auth/local/register`,
    cities: '/cities',
    filials: '/points',
    zone: '/zones',
    payments: '/payments',
    orders: '/orders',
    archive: '/archive',
    settings: '/settings',
    promocodes: '/promocodes',
    cashback: '/cashback',
    chanels: '/chanels',
    messages: '/messages',
    ratings: '/ratings',
    counter: '/counter',
    statistics: '/statistics',
    news: '/news',
    documentation: '/documentation',
    sliders: '/sliders',
    ips: '/blockedips',
    yookassa: '/yookassas',
    goods: '/goods',
    categories: '/categories',
    regionprices: '/regionprices',
    modifications: '/modifications',
    cashbacklogs: '/cashbacklogs',
    updateGoodsPrices: '/updateGoodsPrices',
    pointsLogs: '/pointsLogs',
    phonecodes: '/phonecodes',
    yumaposgoodsmap: '/yumaposgoodmaps',
};

export const roleId = {
    client: 1,
    publick: 2,
    manager: 3,
    admin: 4,
    coordinator: 5,
    operator: 7,
    cashier: 8,
    commodityExpert: 9,
    accountant: 10,
    marketingSpecialist: 11,
    regionalDirector: 12,
    chiefCommoditySpecialist: 13,
    chiefAccountant: 14,
    chiefMarketingSpecialist: 15
};

export const timeDelivery = {
    minDeliveryTime: 60,
    minSamTime: 20
}

export const optionsMinDeliveryTime = [
    { value: 60, label: 60 },
    { value: 75, label: 75 },
    { value: 90, label: 90 },
    { value: 105, label: 105 },
    { value: 120, label: 120 },
];

export const optionsMinSamTime = [
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    { value: 60, label: 60 },
];

export const xmlBackenHost = 'https://sushibox.one/api'; // TODO: Необходимо заменить на адрес в проде
export const operationType = {
    setBalance: 'setBalance',
    minusBalance: 'minusBalance',
}
