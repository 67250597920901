import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export default function DateInput(props) {
    return <DatePicker
        timeCaption="Время"
        showTimeSelect
        portalId="root-portal"
        className="form-control"
        locale="ru"
        dateFormat="dd/MM/yyyy HH:mm"
        timeFormat="HH:mm"
        showMonthDropdown
        {...props}
    />
}