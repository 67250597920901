import { deleteIpAction, setIps, setIp } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [
    forAction(setIps, identityPayload),
    forAction(setIp, (state, { payload: ip }) => {
      const index = state.findIndex((item) => item.id === ip.id);
      const updated = [...state];
      updated[index] = { ...ip };
      return updated;
    }),
    forAction(deleteIpAction, (state, { payload: id }) => {
      return state.filter((item) => item.id !== id);
    })
  ],
  initialState,
);
