import { loadersSelector, yookassaSelector } from './root';
import { propNameSelector } from '../utils';
import { createSelector } from 'reselect';

export const kassaListSelector = propNameSelector(yookassaSelector, 'list');
export const pagingKassaSelector = propNameSelector(yookassaSelector, 'paging');
export const sortingKassaSelector = propNameSelector(yookassaSelector, 'sorting');
export const countKassaSelector = propNameSelector(yookassaSelector, 'count');
export const loaderKassaSelector = propNameSelector(loadersSelector, 'yookassa');
export const loaderAddKassaSelector = propNameSelector(loadersSelector, 'addKassa');
export const loaderUpdateKassaSelector = propNameSelector(loadersSelector, 'updateKassa');
export const loaderDeleteKassaSelector = propNameSelector(loadersSelector, 'deleteKassa');

export const kassaById = (id) => createSelector(
    kassaListSelector,
    (items) => {
        return items.find((item) => item.id === id);
    },
);