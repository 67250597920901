import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';
import { setStatFilters, resetStatFilters, fetchOrdersStat } from '../../action/statistics';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';
import { declOfNum, numberWithSpaces } from '../../utils/common';

const styles = {
    loading: {
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class OrderStats extends Component {
    componentDidMount() {
       this.props.fetchCities();
       this.props.fetchOrdersStat();
    }

    setFilters = (field) => (data) => {
        const { filters } = this.props.orders;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setStatFilters('orders', updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props.orders;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setStatFilters('orders', updated);
    }

    resetFilter = () => {
        this.props.resetStatFilters('orders');
        
    }

    onSearch = () => {
        this.props.fetchOrdersStat();
    }

    isDisabled = () => {
        const { orders } = this.props
        const { filters } = orders;

        if (filters.createdStartDate && filters.createdEndDate) {
            return false;
        }
        return true;
    }

    render() {
        const { orders } = this.props
        const { result, loading, filters } = orders;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Заказы
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div className="dateBlock" style={{ marginRight: 10 }}>
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата от"
                                selected={filters.createdStartDate}
                                onChange={this.onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата до"
                                selected={filters.createdEndDate}
                                onChange={this.onChangeDateInput('createdEndDate')}
                                maxDate={moment(filters.createdStartDate).add({ days: filters.city ? 30 : 15 }).toDate()}
                                required={filters.createdStartDate}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <Select
                                isLoading={this.props.loadingCity || loading}
                                isDisabled={this.props.loadingCity || loading}
                                value={filters.city}
                                closeMenuOnSelect={true}
                                options={this.props.cities.map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                onChange={this.setFilters('city')}
                                placeholder="Город"
                                noOptionsMessage={() => 'Нет городов'}
                            />
                        </div>
                        <button disabled={loading || this.isDisabled()} style={{ marginLeft: 10, marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                        <button disabled={loading} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    {
                        loading || !result ? (
                            <div style={styles.loading}><Loader /></div>
                        ) : (
                            <div className="kt-widget5">
                                <div className="kt-widget5__item">
                                    <div className="kt-widget5__content">
                                        <div className="kt-widget5__section">
                                            <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                                Заказов за выбранный период
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kt-widget5__content">
                                        <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                            <span className="kt-widget5__number">{numberWithSpaces(result ? result.count : 0)}</span>
                                            <span className="kt-widget5__votes">
                                                {declOfNum(result ? result.count : 0, ['заказ', 'заказа', 'заказов'])}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-widget5__item">
                                    <div className="kt-widget5__content">
                                        <div className="kt-widget5__section">
                                            <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                                Средний чек
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kt-widget5__content">
                                        <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                            <span className="kt-widget5__number">{result ? result.check : 0}</span>
                                            <span className="kt-widget5__votes">
                                                {declOfNum(result ? result.check : 0, ['рубль', 'рубля', 'рублей'])}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-widget5__item">
                                    <div className="kt-widget5__content">
                                        <div className="kt-widget5__section">
                                            <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                                Среднее время выполнения заказа
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kt-widget5__content">
                                        <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                            <span className="kt-widget5__number">{result ? result.time : 0}</span>
                                            <span className="kt-widget5__votes">мин</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-widget5__item">
                                    <div className="kt-widget5__content">
                                        <div className="kt-widget5__section">
                                            <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                                Среднее время обработки заказа
                                            </p>
                                        </div>
                                    </div>
                                    <div className="kt-widget5__content">
                                        <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                            <span className="kt-widget5__number">{result ? result.progress : 0}</span>
                                            <span className="kt-widget5__votes">мин</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                   
                </div>
            </div>
                        
        );
    }
}

const mapStateToProps = state => {
    return ({
        orders: state.statistics.orders,
        cities: state.handbook.cities,
        loadingCity: state.loaders.cities,
    });
};

const mapDispatchToProps = {
    fetchOrdersStat: () => fetchOrdersStat(),
    fetchCities: () => fetchCities(true),
    setStatFilters: (name, filters) => setStatFilters(name, filters),
    resetStatFilters: (name) => resetStatFilters(name),
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStats);