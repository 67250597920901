import { setPagingIps } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = {
  start: 0,
  limit: 10
};

export default createReducer(
  [forAction(setPagingIps, identityPayload)],
  initialState,
);
