import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import ActionMenu from '../utils/ActionMenu';
import swal from 'sweetalert';

const deliveryType = {
    all: 'Любая',
    own: 'Самовывоз',
    delivery: 'Доставка'
}

const promocodeType = {
    order: 'Заказ',
    goods: 'Товары',
    category: 'Категория'
};

class List extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Промокод</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Активный</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '200px' }}>Город</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Тип скидки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '120px' }}>Сумма заказа от</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Тип доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.setDetailPromocode(item);
    }
    
    setEditedPromocode = (promocode) => {
        this.props.setEditedPromocode(promocode);
    }

    deletePromocode = (item) => {
        swal({
            title: "Удаление промокода",
            text: "Вы действительно хотите удалить промокод?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deletePromocode(item.id).then(result => {
                    if (result) {
                        this.props.fetchPromocodes();
                    }
                });
            }
        });
    }

    renderCities = (cities) => {
        return cities.map(item => item.name).join(', ');
    }

    renderBody() {
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.setDetailPromocode(item) },
                            { name: 'Редактирование', icon: 'flaticon2-contract', action:  () => this.setEditedPromocode(item) },
                            { name: 'Удалить', icon: 'flaticon2-trash', action:  () => this.deletePromocode(item) }
                        ];
                        return (
                            <tr
                                title="Двойной клик для подробнее..."
                                onDoubleClick={this.handleDoubleClick(item)}
                                key={item.id}
                                className="kt-datatable__row"
                            >
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '150px' }}>{item.name}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>{item.active ? 'Да' : 'Нет'}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '200px' }}>{this.renderCities(item.cities) || 'Любой'}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '123px' }}>{promocodeType[item.type]}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '120px' }}>{item.minPrice} руб.</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '123px' }}>{deliveryType[item.delivery]}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <ActionMenu actions={actions}/>
                                </td>
                            </tr>
                        );
                    }) 
                }
            </tbody>
        );
    }

    render() {
 
        return (
            <table className="kt-datatable__table" style={{ overflow: 'inherit', minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(List);