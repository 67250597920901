import { urls, baseUrl } from '../constants/app';
import request from './utils';
import moment from 'moment';
import axios from 'axios';
import { setError, resetError } from './errors';
axios.defaults.baseURL = baseUrl;

export function fetchRatings(dataParams = {}) {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'ratings', value: true });
    dispatch(resetError('ratings'));
    const paging = getState().ratings.paging;
    const sorting = getState().ratings.sorting;
    const filters = getState().ratings.filters;

    const params = {...dataParams};

    Object.keys(filters).forEach(key => {
        if (filters[key]) {
            dataParams[key] = filters[key].value;
            params[key] = filters[key].value;
        }
    });

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;

    params['_sort'] = `${sorting.field}:${sorting.type}`;

    if (filters.createdStartDate) {
        dataParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    if (filters.createdEndDate) {
        dataParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    request({ method: 'get', url: urls.ratings+'/count', params: dataParams })
        .then(result => {
            dispatch({ type: 'SET_RATINGS_COUNT', count: result });
            return;
        })
        .then(() => {
            return request({ method: 'get', url: urls.ratings, params }).then((response) => {
                dispatch({ type: 'SET_RATINGS', ratings: response });
                dispatch({ type: 'SET_LOADER', field: 'ratings', value: false });
            })
        })
        .catch(error => {
            dispatch(setError(error, 'ratings'));
            dispatch({ type: 'SET_LOADER', field: 'ratings', value: false });
        });
    }
}

export function fetchMiddleScoreRatings() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'ratings', value: true });
        dispatch(resetError('ratings'));
        const filters = getState().ratings.filters;
        const params = {};
        
        if (filters.createdStartDate) {
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        if (filters.createdEndDate) {
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

   
        request({ method: 'get', url: urls.ratings+'/middle', params })
            .then(middle => {
                dispatch({ type: 'SET_RATING_MIDDLE', middle });
                dispatch({ type: 'SET_LOADER', field: 'ratings', value: false });
            })
            .catch(error => {
                dispatch(setError(error, 'ratings'));
                dispatch({ type: 'SET_LOADER', field: 'ratings', value: false });
            });
        }
}

export function setRatingsFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_RATINGS_FILTERS', filters });
    }
}

export function setRatingsPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_RATINGS_PAGING', paging });
    }
}