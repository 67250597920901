import { setSortingSliders } from '../../action';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = {
  field: 'id',
  type: 'desc'
};

export default createReducer(
  [forAction(setSortingSliders, identityPayload)],
  initialState,
);
