import { combineReducers } from 'redux';
import list from './list';
import paging from './paging';
import sorting from './sorting';
import filters from './filters';
import count from './count';
import categories from './categories';
import activeCategory from './activeCategory';
import detail from './detail';

export default combineReducers({
  list,
  paging,
  sorting,
  filters,
  count,
  categories,
  activeCategory,
  detail,
});
