import React from 'react';

const Loader = ({ text = 'Загружаю данные' }) => {
    return (
        <div className="loader">
            <div>{text}</div>
            <div className="kt-spinner kt-spinner--lg kt-spinner--info">
            </div>
        </div>
    );
};

export default Loader;