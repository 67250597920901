import { loadersSelector, goodsSelector } from './root';
import { propNameSelector } from '../utils';
import { createSelector } from 'reselect';

export const goodListSelector = propNameSelector(goodsSelector, 'list');
export const categoryListSelector = propNameSelector(goodsSelector, 'categories');
export const pagingGoodsSelector = propNameSelector(goodsSelector, 'paging');
export const sortingGoodsSelector = propNameSelector(goodsSelector, 'sorting');
export const filtersGoodsSelector = propNameSelector(goodsSelector, 'filters');
export const countGoodsSelector = propNameSelector(goodsSelector, 'count');
export const detailGoodSelector = propNameSelector(goodsSelector, 'detail');
export const loaderGoodsSelector = propNameSelector(loadersSelector, 'goods');
export const loaderDetailGoodSelector = propNameSelector(loadersSelector, 'detailGood');
export const loaderCategoriesSelector = propNameSelector(loadersSelector, 'category');
export const activeCategorySelector = propNameSelector(goodsSelector, 'activeCategory');
export const loaderModalCategorySelector = propNameSelector(loadersSelector, 'modalCategory');
export const loaderModalRegionPriceSelector = propNameSelector(loadersSelector, 'modalRegionPrice');
export const loaderUpdateRegionPriceSelector = propNameSelector(loadersSelector, 'updateRegionPrice');
export const loaderDeleteRegionPriceSelector = propNameSelector(loadersSelector, 'deleteRegionPrice');
export const loaderModalGoodSelector = propNameSelector(loadersSelector, 'modalGood');
export const loaderUpdateGoodSelector = propNameSelector(loadersSelector, 'updateGood');
export const loaderDeleteGoodSelector = propNameSelector(loadersSelector, 'deleteGood');

export const loaderModalModificationSelector = propNameSelector(loadersSelector, 'modalModification');
export const loaderUpdateModificationSelector = propNameSelector(loadersSelector, 'updateModification');
export const loaderDeleteModificationSelector = propNameSelector(loadersSelector, 'deleteModification');

export const selectedCategoryGoods = createSelector(
    goodListSelector,
    activeCategorySelector,
    (items, id) => items.filter((item) => item.category.id === id),
);

export const categoryById = (id) => createSelector(
    categoryListSelector,
    (items) => items.find((item) => item.id === id),
);

export const goodById = (id) => createSelector(
    goodListSelector,
    detailGoodSelector,
    (items, detail) => {
        if (detail && detail.id === id) return detail;
        return items.find((item) => item.id === id);
    },
);
