import { urls } from '../constants/app';
import request from './utils';
import { setError } from './errors';

export function fetchCashbackLogs(dataParams = {}) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'cashbackLogsUser', value: true });
        
        const paging = getState().logs.cashbackLogsUser.paging;
        const params = {
            ...dataParams
        };
       
        params['_start'] = paging.start;
        params['_limit'] = paging.limit;

        request({ method: 'get', url: urls.cashbacklogs+'/count', params: dataParams })
        .then(result => {
            dispatch({ type: 'SET_CASHBACKLOG_USER_COUNT', count: result });
            return;
        })
        .then(() => {
            return request({ method: 'get', url: urls.cashbacklogs, params }).then((response) => {
                dispatch({ type: 'SET_CASHBACKLOGS_USER', logs: response });
                dispatch({ type: 'SET_LOADER', field: 'cashbackLogsUser', value: false });
            })
        })
        .catch(error => {
            dispatch(setError(error, 'cashbackLogsUser'));
            dispatch({ type: 'SET_LOADER', field: 'cashbackLogsUser', value: false });
        });
    }
}


export function setCashbackLogsUserPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_CASHBACKLOG_USER_PAGING', paging });
    }
}



