import { combineReducers } from 'redux';
import user from './user';
import loaders from './loaders';
import errors from './errors';
import users from './users';
import handbook from './handbook';
import orders from './orders';
import goods from './goods';
import settings from './settings';
import promocodes from './promocodes';
import messages from './messages';
import ratings from './ratings';
import statistics from './statistics';
import news from './news';
import documentation from './documentation';
import good from './good';
import modals from './modals';
import sliders from './sliders';
import logs from './logs';
import ips from './ips';
import yookassa from './yookassa';
import phonecodes from './phonecodes';
import yumaposgoodsmap from './yumaposgoodsmap';
import neworders from './neworder';

const rootReducer = combineReducers({
  user,
  users,
  loaders,
  errors,
  handbook,
  messages,
  orders,
  goods,
  settings,
  promocodes,
  ratings,
  statistics,
  news,
  documentation,
  good,
  modals,
  sliders,
  logs,
  yookassa,
  ips,
  phonecodes,
  yumaposgoodsmap,
  neworders,
});

export default rootReducer;