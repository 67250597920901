import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addRegionPrice, setActiveModalAction, updateRegionPrice } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Switch from '../../components/utils/Switch';
import { isEmpty } from 'lodash';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import { CONDITIONS } from '../goods/constants';
import { getNewPrice } from '../../utils/common';

const SelectCity = ({ selected, onChange }) => {
  const dispatch = useDispatch();
  const loadingCities = useSelector(fromStore.loaderCitiesSelector);
  const cities = useSelector(fromStore.citiesListSelector);

  const onChangeSelect = (values) => {
    onChange(values ? values.map((item) => item.id) : '');
  };

  const categoryCitiesIds = selected || [];
  const selectedCities = cities.filter((item) => categoryCitiesIds.includes(item.id));

  useEffect(() => {
    if (isEmpty(cities)) {
      dispatch(fetchCities(true, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Город(а)</label>
        <div className="col-lg-9 col-xl-9">
          <Select
              isLoading={loadingCities}
              isDisabled={loadingCities}
              value={selectedCities}
              options={cities}
              getOptionLabel={(item) => `${item.name}`}
              getOptionValue={(item) => item.id}
              onChange={onChangeSelect}
              isMulti
              isClearable={false}
              placeholder="Выберите город(а)..."
              noOptionsMessage={() => 'Нет городов'}
          />
        </div>
    </div>
  );
}

export function RegionPriceModal() {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).regionPrice;
  const loadingCreate = useSelector(fromStore.loaderModalRegionPriceSelector);
  const loadingUpdate = useSelector(fromStore.loaderUpdateRegionPriceSelector);
  const detail = useSelector(fromStore.detailGoodSelector);
  const loading = loadingUpdate || loadingCreate;

  const regionPrice = detail && detail.regionprices ? detail.regionprices.find((item) => item.id === id) : null;
  const rPrisesIds = detail && detail.regionprices ? detail.regionprices.map((item) => item.id) : [];

  const initData = regionPrice ? {
    goodId: detail.id,
    cities: regionPrice.cities.map((item) => item.id),
    price: regionPrice.price,
    oldPrice: regionPrice.oldPrice,
    condition: regionPrice.condition,
    persent: regionPrice.persent,
    active: regionPrice.active,
  } : {
    goodId: detail.id,
    ids: rPrisesIds,
    cities: [],
    condition: 'equal',
    persent: false,
    active: true,
  };

  const [data, setData] = useState(initData);
  const isEditMode = id !== 'new';

  const onChangeNumberInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = +e.target.value;
    setData(updated);
  };

  const onChangeSwitch = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  };

  const isValid = () => {
    const { price, cities } = data;
    if (isEditMode) {
      if (price && !isEmpty(cities)) return true;
    }
    if (price && !isEmpty(cities)) return true;

    return false;
  }

  const onClose = () => !loading && dispatch(setActiveModalAction({ field: 'regionPrice', value: null }));
  useOnClickOutside(ref, onClose);

  useEffect(() => {
    if (data.condition === 'equal' && data.persent) {
      const updated = { ...data };
      updated.persent = false;
      setData(updated);
    }
  }, [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isEditMode) {
      const result = await dispatch(addRegionPrice(data));
      if (result) {
        onClose();
      }
    } else {
      const result = await dispatch(updateRegionPrice(id, data));
      if (result) {
        onClose();
      }
    }
  };

  const onChangeSelect = (field) => (value) => {
    const updated = {...data};
    if (field === 'condition') {
      updated[field] = value.value;
    } else {
      updated[field] = value;
    }
    setData(updated);
  }

  const conditionsOptions = Object.entries(CONDITIONS).map(([value, label]) => ({ value, label }));
  const selectedCondition = { value: data.condition, label: CONDITIONS[data.condition] };

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование цены' : 'Добавление цены'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
                <SelectCity selected={data.cities} onChange={onChangeSelect('cities')} />
                <div className='row'>
                  <div className='col-6'>
                    <div className="form-group">
                      <label className="form-control-label">Условие *</label>
                      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <div style={{ width: '100px' }}>
                          <Select
                            value={selectedCondition}
                            options={conditionsOptions}
                            onChange={onChangeSelect('condition')}
                            isClearable={false}
                            placeholder="Выберите..."
                            disabled={loading}
                          />
                        </div>
                        {
                          data.condition !== 'equal' && data.condition !== 'multiply' && (
                            <>
                              <span>Процент</span> 
                              <Switch disabled={loading} nomargin={true} onChange={onChangeSwitch('persent')} checked={!!data.persent}/>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Цена({detail.price} ₽) *</label>
                      <input disabled={loading} type="number" value={data.price} className="form-control" onChange={onChangeNumberInput('price')} />
                      { data.price > 0 && (
                        <span class="form-text text-muted">
                          Новое значение: {getNewPrice(detail.price, data.condition, data.price, data.persent)} ₽
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Старая цена({detail.oldPrice || 0} ₽)</label>
                      <input disabled={loading} type="number" value={data.oldPrice} className="form-control" onChange={onChangeNumberInput('oldPrice')} />
                      { data.oldPrice > 0 && (
                        <span class="form-text text-muted">
                          Новое значение: {getNewPrice(detail.oldPrice, data.condition, data.oldPrice, data.persent)} ₽
                        </span>
                      )}
                    </div>
                  </div>
                  
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Включено</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={onChangeSwitch('active')} checked={!!data.active}/>
                    </div>
                </div>
                <ErrorContainer field="modalRegionPrice" hasCloseButton={true}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loading}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loading })}>
                  {isEditMode ? 'Сохранить' : 'Добавить цену'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
